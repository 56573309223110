import React from "react";

const SectionFour = () => {
  return (
    <div className="container-fluid jof-student-1-sect-4">
      <div className="container jof-student-1-sect-4__container">
        <div className={`jof-student-1-sect-4__div-1`}>
          <h4 className="jof-student-1-sect-4__text-sub">Ex-Nihilo</h4>
          <h2 className="jof-student-1-sect-4__text-main j-heading-secondary-white">
            Career Guidance
          </h2>
        </div>
        <div className="jof-student-1-sect-4__div-2">
          <p className="j-paragraph-2 u-margin-bottom-small">
            Ex-NiHiLo provides a range of customized resources to help you
            further improve your skills and put yourself in the best position
            for employment following your studies. Whatever profession you are
            seeking to enter, and whatever degree you pursue, you will be given
            professional guidance and services. If you are still unsure about
            what you want to do or which course would be the best for you, give
            us a call!
          </p>
          <p className="j-paragraph-2 u-margin-bottom-small">
            We can also help you secure a Masters with Advanced Practice, which
            allows you to extend your studies by completing an internship. This
            can help you meet potential employers and provide you with real
            business experience!
          </p>
          <p className="j-paragraph-2 u-margin-bottom-small">
            All courses that our partners offer are all entirely
            government-funded by Student Finance. If you are a UK resident, EU
            citizen working for at least 3 months in the UK, or have refugee
            status.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;

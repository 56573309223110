import React from "react";
import SvgLocation from "../../../components/svg/SvgLocation";

const SectionTwo = () => {
  return (
    <div className="container-fluid jof-student-1-sect-2">
      <h2 className="j-heading-secondary-major jof-student-1-sect-2__header-main">
        Ex-Nihilo work with universities in
      </h2>
      <div className="container jof-student-1-sect-2__container">
        <div className="row text-center jof-student-1-sect-2__row-1">
          <div className="col-12 col-md-4 jof-student-1-sect-2__col">
            <div className="jof-student-1-sect-2__box jof-student-1-sect-2__box-margin-bottom">
              <div className="mb-3 jof-student-1-sect-2__icon">
                <SvgLocation width={64} height={64} />
              </div>
              <h3 className="">LONDON</h3>
              <h5>United Kingdom</h5>
            </div>
          </div>

          <div className="col-12 col-md-4 jof-student-1-sect-2__col">
            <div className="jof-student-1-sect-2__box jof-student-1-sect-2__box-margin-bottom">
              <div className="mb-3 jof-student-1-sect-2__icon">
                <SvgLocation width={64} height={64} />
              </div>
              <h3>LEEDS</h3>
              <h5>United Kingdom</h5>
            </div>
          </div>
          <div className="col-12 col-md-4 jof-student-1-sect-2__col">
            <div className="jof-student-1-sect-2__box">
              <div className="mb-3 jof-student-1-sect-2__icon">
                <SvgLocation width={64} height={64} />
              </div>
              <h3 className="">CARDIFF</h3>
              <h5>United Kingdom</h5>
            </div>
          </div>
        </div>
        <div className="row text-center jof-student-1-sect-2__row-2">
          <div className="col-12 col-md-4 jof-student-1-sect-2__col">
            <div className="jof-student-1-sect-2__box jof-student-1-sect-2__box-margin-bottom">
              <div className="mb-3 jof-student-1-sect-2__icon">
                <SvgLocation width={64} height={64} />
              </div>
              <h3>BIRMINGHAM</h3>
              <h5>United Kingdom</h5>
            </div>
          </div>
          <div className="col-12 col-md-4 jof-student-1-sect-2__col">
            <div className="jof-student-1-sect-2__box jof-student-1-sect-2__box-margin-bottom">
              <div className="mb-3 jof-student-1-sect-2__icon">
                <SvgLocation width={64} height={64} />
              </div>
              <h3>SOUTHAMPTON</h3>
              <h5>United Kingdom</h5>
            </div>
          </div>
          <div className="col-12 col-md-4 jof-student-1-sect-2__col">
            <div className="jof-student-1-sect-2__box">
              <div className="mb-3 jof-student-1-sect-2__icon">
                <SvgLocation width={64} height={64} />
              </div>
              <h3>NEWCASTLE</h3>
              <h5>United Kingdom</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;

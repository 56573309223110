import React from "react";

const SectionFour = () => {
  return (
    <div className="j-home__sect-4 d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-xs-12 col-md-6 j-home__sect-4-img-1">
            <h3 className="j-heading-quatinary-white">100% Free Service</h3>
            <p className="j-paragraph-1">
              Our service is entirely free from helping you to submit your
              application through to registering you onto your degree programme!
            </p>
          </div>
          <div className="col-xs-12 col-md-5 j-home__sect-4-img-sm">
            <div className="row j-home__sect-4-img-2">
              <h3 className="j-heading-quatinary-white">
                2 Days A Week & Government Funded
              </h3>
              <p className="j-paragraph-1">
                Our service is designed so that your degree can fit perfectly
                into your lifestyle.
              </p>
            </div>
            <div className="row j-home__sect-4-img-3">
              <h3 className="j-heading-quatinary-white">
                Scholarships For International Students
              </h3>
              <p className="j-paragraph-1">
                Ex-NiHiLo student advisors will make sure to apply for every
                scholarship you’re eligible for.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;

import React from "react";
import SvgHome from "./svg/SvgHome";
import SvgBooks from "./svg/SvgBooks";
import SvgManWoman from "./svg/SvgManWoman";
import SvgAboutus from "./svg/SvgAboutus";
import SvgAsk from "./svg/SvgAsk";

const Sidebar = () => {
  return (
    <div
      className="offcanvas offcanvas-end jof-sidebar"
      data-bs-scroll="true"
      tabIndex="-1"
      id="offcanvasWithBothOptions"
      aria-labelledby="offcanvasWithBothOptionsLabel"
    >
      <div className="offcanvas-header">
        <h2
          className="offcanvas-title j-heading-quatinary-blue"
          id="offcanvasWithBothOptionsLabel"
        >
          EX-NIHILO
        </h2>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <nav className="jof-sidebar__nav">
          <div className="jof-sidebar__item">
            <a href="/">
              <SvgHome width={26} height={26} color="#8081b7" />{" "}
              <span className="ms-2">Home</span>
            </a>
          </div>
          <div className="jof-sidebar__item">
            <a href="/courses">
              <SvgBooks width={26} height={26} color="#8081b7" />{" "}
              <span className="ms-2">Courses</span>
            </a>
          </div>
          <div className="jof-sidebar__item">
            <a href="/uk-eu-students">
              <SvgManWoman width={26} height={26} color="#8081b7" />{" "}
              <span className="ms-2">UK & EU Students</span>
            </a>
          </div>
          <div className="jof-sidebar__item">
            <a href="/international-students">
              <SvgManWoman width={26} height={26} color="#8081b7" />{" "}
              <span className="ms-2"> International Students</span>
            </a>
          </div>
          <div className="jof-sidebar__item">
            <a href="/about-us">
              <SvgAboutus width={26} height={26} color="#8081b7" />{" "}
              <span className="ms-2">About us</span>
            </a>
          </div>
          <div className="jof-sidebar__item">
            <a href="/contact-us">
              <SvgAsk width={26} height={26} color="#8081b7" />{" "}
              <span className="ms-2">Enquire</span>
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;

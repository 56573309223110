import React from "react";
import Header from "./Header";
import Meta from "./Meta";

const ErrorPage = ({ error }) => {
  return (
    <div className="jof-error-page">
      <Meta title={"Error 404"} />
      <Header />
      <div className="jof-error-page__content">
        <h1 className="fw-bolder">Problem Loading Page</h1>
        <h2 className="text-center fw-bold">Error 404</h2>
        <p className="text-center" style={{ fontSize: "1.4em" }}>
          Course not found
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;

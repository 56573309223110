import React from "react";

const SectionOne = () => {
  return (
    <div className="jof-contact-us-sect-1">
      <div className="jof-contact-us-sect-1__text">
        <h2 className="j-heading-secondary-white">Contact Us</h2>
        <p className="j-paragraph-1">
          Have a question for us? Contact us using the details below and our
          team will be happy to help.
        </p>
      </div>
    </div>
  );
};

export default SectionOne;

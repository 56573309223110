import React from "react";

const SectionTwo = () => {
  return (
    <div className="jof-courses-sect-2">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            <p className="j-paragraph-2 fw-bold">
              Students preparing for undergraduate study. Working professionals
              looking to specialise in their field. Career changers. Everyone
              should be given access to outstanding higher education, and our
              aim is to make that possible.
            </p>
            <p className="j-paragraph-2 mt-3">
              To learn more about one of the courses? Simply click on the course
              name.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;

import React from "react";

const Carousel = ({
  carouselType,
  itemOne,
  itemTwo,
  itemThree,
  titleOne,
  titleTwo,
  titleThree,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
}) => {
  return (
    <div
      id="carouselExampleCaptions"
      className={`carousel slide ${carouselType}`}
      data-bs-ride="carousel"
    >
      <div className="carousel-inner jof-carousel-1">
        <div className={`carousel-item active jof-carousel-1__item ${itemOne}`}>
          <div className="carousel-caption jof-carousel-1__caption-indicators">
            <h1 className="jof-carousel-1__header">{titleOne}</h1>
            <p className="jof-carousel-1__paragraph">{descriptionOne}</p>
            <div className="jof-carousel-1__indicators">
              <i
                className="bi bi-1-circle-fill"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                aria-label="Slide 1"
              ></i>

              <i
                className="bi bi-1-circle-fill"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></i>
              <i
                className="bi bi-1-circle-fill"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></i>
            </div>
          </div>
        </div>
        <div className={`carousel-item jof-carousel-1__item ${itemTwo}`}>
          <div className="carousel-caption jof-carousel-1__caption-indicators">
            <h1 className="jof-carousel-1__header">{titleTwo}</h1>
            <p className="jof-carousel-1__paragraph">{descriptionTwo}</p>
            <div className="jof-carousel-1__indicators">
              <i
                className="bi bi-1-circle-fill"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                aria-label="Slide 1"
              ></i>

              <i
                className="bi bi-1-circle-fill"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></i>
              <i
                className="bi bi-1-circle-fill"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></i>
            </div>
          </div>
        </div>
        <div className={`carousel-item jof-carousel-1__item ${itemThree}`}>
          <div className="carousel-caption jof-carousel-1__caption-indicators">
            <h1 className="jof-carousel-1__header">{titleThree}</h1>
            <p className="jof-carousel-1__paragraph">{descriptionThree}</p>
            <div className="jof-carousel-1__indicators">
              <i
                className="bi bi-1-circle-fill"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                aria-label="Slide 1"
              ></i>

              <i
                className="bi bi-1-circle-fill"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></i>
              <i
                className="bi bi-1-circle-fill"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev d-none d-md-block"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next d-none d-md-block"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;

import { useQuery } from "@tanstack/react-query";
import { getCourses } from "../courseService";

export function useCourses(currentPage) {
  return useQuery(["courses", currentPage], () => getCourses(currentPage));
}

export function useCoursesRefetch(currentPage) {
  return useQuery(
    ["courses-refetch", currentPage],
    () => getCourses(currentPage),
    {
      enabled: false,
    }
  );
}

import React, { useState } from "react";

const SearchPopup = ({
  showSearchPopup,
  handleShowSearchPopup: setSearchPopup,
}) => {
  const [keyword, setKeyword] = useState("");

  const show_search_popup = showSearchPopup ? "show-search-popup" : null;

  return (
    <div className={`jof-search-popup ${show_search_popup}`}>
      <div className="jof-search-popup__form">
        <input
          type="text"
          className="jof-search-popup__input"
          name="jof-search"
          placeholder="keyword...."
          onChange={(e) => setKeyword(e.target.value)}
        />
        <button className="jof-search-popup__button">
          <a href={`/courses/search?keyword=${keyword}`}>
            <i className="bi bi-search jof-search-popup__search"></i>{" "}
          </a>
        </button>
        <i
          className="bi bi-x-lg jof-search-popup__close"
          onClick={() => setSearchPopup()}
        ></i>
      </div>
    </div>
  );
};

export default SearchPopup;

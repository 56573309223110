import React from "react";

const Germany = () => {
  return (
    <div className="jof-other-courses__unis">
      <h4 className="mb-5">Germany</h4>
      <div className="jof-other-courses__unis-universities">
        <h5>University</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>SCHILLER INTERNATIONAL UNIVERSITY.</span> <br />
              <span>Heidelberg, Germany.</span> <br />
            </div>
          </div>
        </div>
        <h5>Undergraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">BSc International Business</li>
            <li className="col-12 col-md-3">
              BA International Relation and Diplomacy
            </li>
          </ul>
        </div>
        <h5>Postgraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">MBA Business Administration</li>
            <li className="col-12 col-md-3">MBA International Business</li>
            <li className="col-12 col-md-3">
              MA International Relation and Diplomacy
            </li>
          </ul>
        </div>
        <h5>University</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>MACROMEDIA UNIVERSITY</span> <br />
              <span>Berlin,Germany.</span> <br />
            </div>
          </div>
        </div>
        <h5>Undergraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">BA Acting</li>
            <li className="col-12 col-md-3">BS Artificial Intelligence</li>
            <li className="col-12 col-md-3">BA Brand Management</li>
            <li className="col-12 col-md-3">BA Business Management</li>
            <li className="col-12 col-md-3">BA Design</li>
            <li className="col-12 col-md-3">
              BA Digital Technology and Coding
            </li>
            <li className="col-12 col-md-3">BA Fashion Design</li>
            <li className="col-12 col-md-3">BA Management</li>
            <li className="col-12 col-md-3">BA Film making</li>
            <li className="col-12 col-md-3">BA Media Management</li>
            <li className="col-12 col-md-3">BA Media & Communication Design</li>
            <li className="col-12 col-md-3">
              BA Media & communication Management
            </li>
            <li className="col-12 col-md-3">BS Software Engineering</li>
            <li className="col-12 col-md-3">BS UI/UX Design</li>
          </ul>
        </div>
        <h5>Undergraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">MBA Business Administration</li>
            <li className="col-12 col-md-3">MBA International Business</li>
            <li className="col-12 col-md-3">MA Brand Management</li>
            <li className="col-12 col-md-3">BA Business Management</li>
            <li className="col-12 col-md-3">MA Design Management</li>
            <li className="col-12 col-md-3">MA Digital Media Business</li>
            <li className="col-12 col-md-3">MBA Data Analytics</li>
            <li className="col-12 col-md-3">MBA Marketing</li>
            <li className="col-12 col-md-3">
              MA Media & Communication Management
            </li>
            <li className="col-12 col-md-3">MA Music Management</li>
            <li className="col-12 col-md-3">MA Strategic Marketing</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Germany;

import React, { useState, useRef, useEffect } from "react";
import ButtonLink from "../../../components/ButtonLink";

const SectionOne = () => {
  const div_1Ref = useRef(null);

  const [inView, setInView] = useState(null);

  function handleScroll() {
    const bounding = div_1Ref.current.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    const showOnHeight = windowHeight / 5;

    // console.log(showOnHeight);
    // console.log(windowHeight);

    // console.log("bounding 1, top:", bounding.top);
    // console.log("bounding 1, bottom:", bounding.bottom);

    // Detect when element is fully visible in view port
    if (
      bounding.left >= 0 &&
      bounding.top <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    ) {
      // console.log("Element is in the viewport!");

      if (showOnHeight >= bounding.top && windowHeight < bounding.bottom) {
        setInView("show-div-1");
      } else if (
        showOnHeight <= bounding.top &&
        windowHeight < bounding.bottom
      ) {
        setInView("hide-div-1");
      } else if (windowHeight > bounding.bottom) {
        setInView("hide-div-1");
      }
    } else {
      // console.log("Element is NOT in the viewport!");
      setInView("hide-div-1");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`j-home__sect-1`} ref={div_1Ref}>
      <div className={`j-home__sect-1__div-1-imaginary`}>
        <h4 className="j-home__sect-1__text-sub">WHAT WE DO</h4>
        <h2 className="j-home__sect-1__text-main j-heading-secondary-black">
          EX-NIHILO CARE SERVICES & CONSULTANCY
        </h2>
      </div>
      <div className={`j-home__sect-1__div-1 ${inView}`}>
        <h4 className="j-home__sect-1__text-sub">WHAT WE DO</h4>
        <h2 className="j-home__sect-1__text-main j-heading-secondary-black">
          EX-NIHILO CARE SERVICES & CONSULTANCY
        </h2>
      </div>

      <div className="j-home__sect-1__div-2">
        <p className="j-paragraph-1 u-margin-bottom-small">
          <span className="u-format-primary">
            Our service is entirely free:
          </span>{" "}
          from helping you to submit your application through to registering you
          onto your degree programme! Our student support team will guide you
          through the full application process.
        </p>
        <p className="j-paragraph-1 u-margin-bottom-small">
          <span className="u-format-primary">Ex-NiHiLo</span> offer numerous
          different degree programs with 2 days a week attendance, fully
          government-funded by Student Finance.
        </p>
        <p className="j-paragraph-1 u-margin-bottom-small">
          We work with universities in London, Birmingham, Leeds, Manchester,
          Newcastle, Greater Cardiff, Southampton.
        </p>
        <p className="j-paragraph-1 u-margin-bottom-small">
          We can also connect you with online courses if this style of study
          suits your requirements. We will help you with the full application
          process from start until you are registered.
        </p>
        <p className="j-paragraph-1 u-margin-bottom-small">
          <span className="u-format-primary">Ex-NiHiLo</span> can help you to
          connect to a wide range of foundation up to master degree courses and
          will help you find the perfect study path for your chosen degree.
        </p>
        <p className="j-paragraph-1 u-margin-bottom-small">
          Our team will guide you through the whole process by submitting your
          application and preparing you for every interview or test that may be
          required for attendance.
        </p>
        <p className="j-paragraph-1 u-margin-bottom-small">
          We can also assist you with your Student Finance application!
        </p>
        <ButtonLink
          label="Get in touch"
          icon="bi bi-telephone"
          link="/contact-us"
        />
      </div>
    </div>
  );
};

export default SectionOne;

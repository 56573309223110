import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SearchPopup from "./SearchPopup";

const Header = () => {
  const [showSearchPopup, setShowSearchPopup] = useState(false);

  const handleShowSearchPopup = () => {
    setShowSearchPopup(!showSearchPopup);
  };

  return (
    <>
      <nav className="container-fluid j-header__nav">
        <div className="j-header__nav-logo">
          <a href="/">
            <img src="/img/logo-small.png" alt="logo" />
          </a>
        </div>
        <div className="j-header__nav-list">
          <NavLink to="/" className="j-header__link">
            Home
          </NavLink>
          <NavLink to="/courses" className="j-header__link">
            Courses
          </NavLink>
          <div className="j-header__link j-header__dropdown-wrapper">
            Student
            <ul className="j-header__dropdown">
              <li className="">
                <NavLink to="/uk-eu-students">UK & EU Students</NavLink>
              </li>{" "}
              <li className="">
                <NavLink to="/international-students">
                  International Students
                </NavLink>
              </li>
            </ul>
          </div>
          <NavLink to="/about-us" className="j-header__link">
            About
          </NavLink>
          <NavLink to="/contact-us" className="j-header__link">
            Enquire
          </NavLink>
          <div
            onClick={() => handleShowSearchPopup()}
            className="j-header__search j-header__icon text-center ms-3"
          >
            <i className="bi bi-search fs-2"></i> <br />
            <span className="">Search</span>
          </div>
          <div
            className="j-header__menu-btn j-header__icon text-center"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
          >
            <i className="bi bi-list fs-2"></i> <br />
            <span className="">Menu</span>
          </div>
        </div>
      </nav>
      <SearchPopup
        showSearchPopup={showSearchPopup}
        handleShowSearchPopup={handleShowSearchPopup}
      />
    </>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCourseFilter } from "../../../services/hooks/useCourseFilter";
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Pagination";
import Table from "./Table";

const FilteredCourses = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const field = searchParams.get("field");
  const selection = searchParams.get("selection");

  const [page, setPage] = useState(1);

  const { data, isLoading, isError, error, isSuccess, refetch } =
    useCourseFilter({
      field,
      selection,
      page,
    });

  const handlePrevious = () => {
    setPage(page - 1);
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  const goToPage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    refetch();
  });

  //destructure allCourse
  const { pages: totalPage, hasNextPage } = data || {};
  return (
    <>
      <h3 className="j-heading-quatinary-black">
        {" "}
        {data?.totalCourses} filtered courses
      </h3>
      {isLoading && <Loading color="#8081b7" />}
      {isError && <p className="j-error-message">{error.message}</p>}
      <Table courses={data?.courses ? data.courses : []} />

      <Pagination
        page={page}
        totalPage={totalPage}
        hasNextPage={hasNextPage}
        previous={handlePrevious}
        next={handleNext}
        goToPage={goToPage}
      />
    </>
  );
};

export default FilteredCourses;

import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import SearchPopup from "../../../components/SearchPopup";
import SearchBox from "./SearchBox";

const Header = () => {
  const navRefMain = useRef(null);
  const navRef = useRef(null);
  const [showSearchPopup, setShowSearchPopup] = useState(false);

  const handleScroll = () => {
    // Detect when there is scroll bellow top
    if (window.scrollY > 0) {
      navRefMain.current.style.display = "flex";
      navRef.current.style.opacity = "0";
      navRef.current.style.visibility = "hidden";
    }

    // Detect when scroll remain at the top
    if (window.scrollY === 0) {
      navRefMain.current.style.display = "none";
      navRef.current.style.opacity = "1";
      navRef.current.style.visibility = "visible";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleShowSearchPopup = () => {
    setShowSearchPopup(!showSearchPopup);
  };

  return (
    <>
      <header className="j-home-header">
        <nav
          className="container-fluid j-home-header-main__nav"
          ref={navRefMain}
        >
          <div className="j-home-header-main__nav-logo">
            <a href="/">
              <img src="img/logo-small.png" alt="logo" />
            </a>
          </div>
          <div className="j-home-header-main__nav-list">
            <NavLink to="/" className="j-home-header-main__link">
              Home
            </NavLink>
            <NavLink to="/courses" className="j-home-header-main__link">
              Courses
            </NavLink>
            <div className="j-home-header-main__link j-home-header-main__dropdown-wrapper">
              Student
              <ul className="j-home-header-main__dropdown">
                <li className="">
                  <NavLink to="/uk-eu-students">UK & EU Students</NavLink>
                </li>{" "}
                <li className="">
                  <NavLink to="/international-students">
                    International Students
                  </NavLink>
                </li>
              </ul>
            </div>
            <NavLink to="/about-us" className="j-home-header-main__link">
              About
            </NavLink>
            <NavLink to="/contact-us" className="j-home-header-main__link">
              Enquire
            </NavLink>
            <div
              onClick={() => handleShowSearchPopup()}
              className="j-home-header-main__search j-home-header-main__icon text-center ms-3"
            >
              <i className="bi bi-search fs-2"></i> <br />
              <span className="">Search</span>
            </div>
            <div
              className="j-home-header-main__menu-btn j-home-header-main__icon text-center"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBothOptions"
              aria-controls="offcanvasWithBothOptions"
            >
              <i className="bi bi-list fs-2"></i> <br />
              <span className="">Menu</span>
            </div>
          </div>
        </nav>
        <div className="container-fluid j-home-header__topmost">
          <div className="j-home-header__topmost-item">
            {" "}
            07511187493 | Email : info@ex-nihilocareservicesandconsultancy.com
          </div>
        </div>
        <nav className={`container-fluid j-home-header__nav`} ref={navRef}>
          <div className="j-home-header__nav-logo">
            <a href="/">
              <img src="img/logo-small.png" alt="logo" />
            </a>
          </div>
          <div className="j-home-header__nav-list">
            <NavLink to="/" className="j-home-header__link">
              Home
            </NavLink>
            <NavLink to="/courses" className="j-home-header__link">
              Courses
            </NavLink>
            <div className="j-home-header__link j-home-header__dropdown-wrapper">
              Student
              <ul className="j-home-header__dropdown">
                <li className="">
                  <NavLink to="/uk-eu-students">UK & EU Students</NavLink>
                </li>{" "}
                <li className="">
                  <NavLink to="/international-students">
                    International Students
                  </NavLink>
                </li>
              </ul>
            </div>
            <NavLink to="/about-us" className="j-home-header__link">
              About
            </NavLink>
            <NavLink to="/contact-us" className="j-home-header__link">
              Enquire
            </NavLink>
            <div
              onClick={() => handleShowSearchPopup()}
              className="j-home-header__search j-home-header__icon text-center ms-3"
            >
              <i className="bi bi-search fs-2"></i> <br />
              <span className="">Search</span>
            </div>
            <div
              className="j-home-header__menu-btn j-home-header__icon text-center"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBothOptions"
              aria-controls="offcanvasWithBothOptions"
            >
              <i className="bi bi-list fs-2"></i> <br />
              <span className="">Menu</span>
            </div>
          </div>
        </nav>
        <div className="j-home-header__container">
          <div className="j-home-header__text-container">
            <h1 className="j-heading-primary">
              We Offer The Easiest And Friendly Path To Securing Your Study
              Abroad.
            </h1>
            <p className="j-home-header__paragraph">
              If you are a UK resident and would like to apply for university,
              We are here to help. Our service is entirely free!
            </p>
          </div>

          <div className="j-home-header__search-container">
            <SearchBox />
          </div>
        </div>
      </header>
      <SearchPopup
        showSearchPopup={showSearchPopup}
        handleShowSearchPopup={handleShowSearchPopup}
      />
    </>
  );
};

export default Header;

import React from "react";

const SvgAccessibility = ({ color, width, height }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M6.5 1.5c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5z"
      ></path>
      <path
        fill={color}
        d="M10 5l5.15-2.221-0.371-0.929-6.279 2.15h-1l-6.279-2.15-0.371 0.929 5.15 2.221v4l-2.051 6.634 0.935 0.355 2.902-6.489h0.429l2.902 6.489 0.935-0.355-2.051-6.634z"
      ></path>
    </svg>
  );
};

SvgAccessibility.defaultProps = {
  color: "#8081b7",
  width: 32,
  height: 32,
};

export default SvgAccessibility;

import React, { useState } from "react";
import { useCourses } from "../../../services/hooks/useCourses";
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Pagination";
import Table from "./Table";

const AllCourses = () => {
  const [page, setPage] = useState(1);

  const { data, isLoading, isError, error, isSuccess } = useCourses(page);

  const handlePrevious = () => {
    setPage(page - 1);
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  const goToPage = (page) => {
    setPage(page);
  };

  //destructure allCourse
  const { pages: totalPage, hasNextPage } = data || {};
  return (
    <>
      <h3 className="j-heading-quatinary-black">
        {data?.totalCourses} courses available
      </h3>
      {isLoading && <Loading color="#8081b7" />}
      {isError && <p className="j-error-message">{error.message}</p>}
      <Table courses={data?.courses ? data.courses : []} />

      <Pagination
        page={page}
        totalPage={totalPage}
        hasNextPage={hasNextPage}
        previous={handlePrevious}
        next={handleNext}
        goToPage={goToPage}
      />
    </>
  );
};

export default AllCourses;

import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCourseEnquiry } from "../../../services/hooks/useCourseEnquiry";
import Loading from "../../../components/Loading";

const schema = yup.object().shape({
  fullName: yup
    .string()
    .required("Your full name is required")
    .label("Full name"),
  email: yup.string().email().required("Email is required").label("Email"),
  telephone: yup
    .string("Input a valid telephone number")
    .required("Telephone number is required")
    .label("Telephone"),
  city: yup.string().required("You must input city").label("City"),
  country: yup
    .string()
    .required("You must input your country or region")
    .label("Country/Region"),
  course: yup
    .string()
    .required("Input the course you want to study")
    .label("Course"),
  startTime: yup
    .string()
    .required("Program start time is required")
    .label("Start time"),
  additionalNotes: yup.string().label("Additional notes"),
});

const EnquiryForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { isLoading, isError, error, isSuccess, mutate } = useCourseEnquiry();

  const onSubmit = (data) => {
    // console.log(data);

    mutate(data);
  };

  return (
    <div className="jof-course-enquiry-form">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="jof-contact-us-sect-3__form"
            >
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Your name"
                  {...register("fullName")}
                />
                <label htmlFor="floatingInput">
                  Full name{" "}
                  <sup>
                    <i className="bi bi-asterisk jof-course-enquiry-form__asterik"></i>
                  </sup>
                </label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.fullName?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  {...register("email")}
                />
                <label htmlFor="floatingInput">
                  Email address
                  <sup>
                    <i className="bi bi-asterisk jof-course-enquiry-form__asterik"></i>
                  </sup>
                </label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.email?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="tel"
                  className="form-control"
                  id="floatingInput"
                  placeholder="+13243345679"
                  {...register("telephone")}
                />
                <label htmlFor="floatingInput">
                  Telephone number
                  <sup>
                    <i className="bi bi-asterisk jof-course-enquiry-form__asterik"></i>
                  </sup>
                </label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.telephone?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="City"
                  {...register("city")}
                />
                <label htmlFor="floatingInput">
                  Your city
                  <sup>
                    <i className="bi bi-asterisk jof-course-enquiry-form__asterik"></i>
                  </sup>
                </label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.city?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Notes"
                  {...register("country")}
                />
                <label htmlFor="floatingInput">
                  Your country/region
                  <sup>
                    <i className="bi bi-asterisk jof-course-enquiry-form__asterik"></i>
                  </sup>
                </label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.country?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Notes"
                  {...register("course")}
                />
                <label htmlFor="floatingInput">
                  Input course name
                  <sup>
                    <i className="bi bi-asterisk jof-course-enquiry-form__asterik"></i>
                  </sup>
                </label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.course?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  {...register("startTime")}
                >
                  <option className="">Select</option>
                  <option className="" value="less than 6 month">
                    less than 6 month
                  </option>
                  <option value=" less than a year">less than a year</option>
                  <option value="1 year">1 year</option>
                </select>
                <label htmlFor="floatingSelect">
                  When are you looking to start?
                  <sup>
                    <i className="bi bi-asterisk jof-course-enquiry-form__asterik"></i>
                  </sup>
                </label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.startTime?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  type="text"
                  className="form-control jof-course-enquiry-form__textarea"
                  id="floatingInput"
                  placeholder="Notes"
                  {...register("additionalNotes")}
                ></textarea>
                <label htmlFor="floatingInput">Additional notes</label>
              </div>
              <p className="j-paragraph-1 jof-contact-us-sect-3__form-para">
                By providing your email address and/or phone number, you are
                confirming that you give us permission to respond to your
                enquiry via these means.
              </p>
              {isSuccess && (
                <p className="j-paragraph-1 fw-bold">
                  Enquiry sent successfully. <br /> Please, do not resend. We
                  will contact you soon.
                  <br />
                  Thank you
                </p>
              )}
              {isError && (
                <p className="j-paragraph-1">{error.response?.data?.message}</p>
              )}
              <div>
                {isLoading ? (
                  <Loading color={"white"} size="60" />
                ) : (
                  <button type="submit" className="btn btn-secondary btn-lg">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;

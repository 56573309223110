import React from "react";
import { Link } from "react-router-dom";
import { formatCourseUrl } from "./../../../utils/urlHepler";

const Table = ({ courses }) => {
  return (
    <table className="table table-hover table-bordered border-dark jof-courses-sect-3__table">
      <thead>
        <tr className="jof-courses-sect-3__thead-row">
          <th scope="col">Course</th>
          <th scope="col">Location</th>
          <th scope="col">Study Mode</th>
          <th scope="col">Level of Study</th>
          <th scope="col">Institution</th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course) => (
          <tr key={course._id}>
            <td>
              {course.url ? (
                <a
                  href={`${course.url}`}
                  target={"_blank"}
                  rel={"noreferrer"}
                  className="jof-courses-sect-3__course-link"
                >
                  {" "}
                  {course.title}
                </a>
              ) : (
                <a
                  href={`/courses/${formatCourseUrl(course.title, course._id)}`}
                  className="jof-courses-sect-3__course-link"
                >
                  {course.title}
                </a>
              )}
            </td>
            <td>{course.location}</td>
            <td>{course.studyMode}</td>
            <td>{course.levelOfStudy}</td>
            <td>{course.institution}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;

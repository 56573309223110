import React from "react";
import { Link } from "react-router-dom";

const FilterBy = () => {
  return (
    <>
      <h3 className="j-heading-quatinary-black mt-5 mb-3">Filter by</h3>
      <div
        className="accordion accordion-flush jof-courses-sect-3__accordion"
        id="accordionFlushExample"
      >
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed jof-courses-sect-3__accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              Field
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link to="/courses/filter-by?field=title&selection=Accounting">
                  Accounting
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link to="/courses/filter-by?field=title&selection=Computing">
                  Computing
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link to="/courses/filter-by?field=title&selection=Cyber">
                  Cyber
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link to="/courses/filter-by?field=title&selection=Business">
                  Business
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=title&selection=${"Project Management"}`}
                >
                  Project Management
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=title&selection=${"Art Design and Media"}`}
                >
                  Art Design and Media
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link to={`/courses/filter-by?field=title&selection=${"Data"}`}>
                  Data
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=title&selection=${"Engineering"}`}
                >
                  Engineering
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=title&selection=${"Technology"}`}
                >
                  Technology
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=title&selection=${"Science"}`}
                >
                  Science
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed jof-courses-sect-3__accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              Course Level
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=levelOfStudy&selection=${"Undergraduate"}`}
                >
                  Undergraduate Degrees
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=levelOfStudy&selection=${"Pre-Masters"}`}
                >
                  Pre-Masters Programmes
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=levelOfStudy&selection=${"Postgraduate"}`}
                >
                  Postgraduate Degrees
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=levelOfStudy&selection=${"Foundation Programmes"}`}
                >
                  Foundation Programmes
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed jof-courses-sect-3__accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              City
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=location&selection=${"London"}`}
                >
                  London
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=location&selection=${"Manchester"}`}
                >
                  Manchester
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=location&selection=${"Newcastle"}`}
                >
                  Newcastle
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=location&selection=${"Southampton"}`}
                >
                  Southampton
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=location&selection=${"Birmingham"}`}
                >
                  Birmingham
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=location&selection=${"Online"}`}
                >
                  Online
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=location&selection=${"Cardiff"}`}
                >
                  Cardiff
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed jof-courses-sect-3__accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              Study Mode
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=studyMode&selection=${"Full-time"}`}
                >
                  Full-time
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=studyMode&selection=${"Part-time"}`}
                >
                  Part-time
                </Link>
              </div>
              <div className="jof-courses-sect-3__accordion-body-item">
                <Link
                  to={`/courses/filter-by?field=modeOfStudy&selection=${"Higher and Degree Apprenticeship"}`}
                >
                  Higher and Degree Apprenticeship
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              onClick={() => {
                window.location = "/courses";
              }}
              className="accordion-button collapsed jof-courses-sect-3__accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFive"
              aria-expanded="false"
              aria-controls="flush-collapseFive"
            >
              All Course
            </button>
          </h2>
        </div>
      </div>
    </>
  );
};

export default FilterBy;

import React from "react";
import ReactPlayer from "react-player/youtube";

const SectionTwo = ({ course }) => {
  return (
    <div className="jof-course-sect-2">
      <div className="container jof-course-sect-2__container">
        <h3 className="j-heading-quatinary-black mb-1 mb-md-5 text-center">
          Course overview
        </h3>
        <ul className="list-group list-group-flush jof-student-2-sect-2__list-group">
          <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
            <span className="jof-course-sect-2__course-property">
              Level of study:
            </span>{" "}
            {course.levelOfStudy}
          </li>
          <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
            <span className="jof-course-sect-2__course-property">Award:</span>{" "}
            {course.award}
          </li>
          {course.apprenticeshipStandard?.title && (
            <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
              <span className="jof-course-sect-2__course-property">
                Apprenticeship standard:
              </span>{" "}
              <a
                href={`${course.apprenticeshipStandard?.url}`}
                className="jof-course-sect-2__course-prop-link"
                target={"_blank"}
                rel="noreferrer"
              >
                {course.apprenticeshipStandard?.title}
              </a>
            </li>
          )}

          {course.apprenticeshipCertificate && (
            <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
              <span className="jof-course-sect-2__course-property">
                Apprenticeship Certificate:
              </span>{" "}
              {course.apprenticeshipCertificate}
            </li>
          )}

          <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
            <span className="jof-course-sect-2__course-property">Fees:</span>{" "}
            {course.fees}
          </li>
          <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
            <span className="jof-course-sect-2__course-property">
              Entry requirements:
            </span>{" "}
            {course.entryRequirements}
          </li>
          <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
            <span className="jof-course-sect-2__course-property">
              English language requirements:
            </span>{" "}
            {course.engLangRequirements}
          </li>
          <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
            <span className="jof-course-sect-2__course-property">
              Mode of study:
            </span>{" "}
            {course.modeOfStudy}
          </li>
          <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
            <span className="jof-course-sect-2__course-property">
              Duration:
            </span>{" "}
            {course.duration}
          </li>
          <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
            <span className="jof-course-sect-2__course-property">
              Assessment methods:
            </span>{" "}
            {course.assessmentMethods}
          </li>
          {course?.scholarshipBursaries && (
            <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
              <span className="jof-course-sect-2__course-property">
                Scholarship or bursaries:
              </span>{" "}
              {course.scholarshipBursaries}
            </li>
          )}
          {course?.paymentPlan && (
            <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
              <span className="jof-course-sect-2__course-property">
                Payment plan:
              </span>{" "}
              {course.paymentPlan}
            </li>
          )}

          {course?.studentFinance && (
            <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
              <span className="jof-course-sect-2__course-property">
                Student finance:
              </span>{" "}
              {course.studentFinance}
            </li>
          )}

          <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
            <span className="jof-course-sect-2__course-property">
              Start date:
            </span>{" "}
            {course.startDates}
          </li>
          <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
            <span className="jof-course-sect-2__course-property">
              Locations:
            </span>{" "}
            {course.locations}
          </li>
        </ul>
        {/* <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <div className="jof-course-sect-2__video my-5">
              <ReactPlayer url={course.video?.url} height="100%" width="100%" />
            </div>
          </div>
        </div> */}
        {course.EUFunding && (
          <>
            <p className="j-paragraph-1 jof-course-sect-2__course-property mt-5">
              PLEASE NOTE: To be eligible for an Higher Apprenticeship
              programme, learners must:
            </p>
            <p className="j-paragraph-1">
              (a) be currently in full-time employment and based in the UK
            </p>
            <p className="j-paragraph-1">
              (b) be interested in completing a Higher Apprenticeship with their
              current employer
            </p>
            <div className="jof-course-sect-2__img-container my-5">
              <img src="/img/ESFA.jpg" alt="ESFA" />
              <img src="/img/ESF.jpg" alt="ESF" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SectionTwo;

import { useQuery } from "@tanstack/react-query";
import { filterCourse } from "../courseService";

export function useCourseFilter({ field, selection, page }) {
  return useQuery(
    ["course-filter", page],
    () => filterCourse({ field, selection, page }),
    {
      enabled: false,
    }
  );
}

// export function useCourseFilter() {
//   return useMutation(filterCourse, {
//     mutationKey: "course-filter",
//   });
// }

import React from "react";

const SvgHome = ({ color, width, height }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M16 9.226l-8-6.21-8 6.21v-2.532l8-6.21 8 6.21zM14 9v6h-4v-4h-4v4h-4v-6l6-4.5z"
      ></path>
    </svg>
  );
};

SvgHome.defaultProps = {
  color: "#8081b7",
  width: 32,
  height: 32,
};

export default SvgHome;

import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Ex-Nihilo Care Services and Consultancy",
  description:
    "Ex-NiHiLo Care Services and Consultancy was established in 2021 with the desire to help people access higher education. Our head office is in London, United Kingdom. Every student is treated special and provided with the best service. As contributors to ensuring you get the best education, all our services are free. From course application through to registration and beyond, our team of student advisors are here to support every student through the admissions process.",
  keywords:
    "Ex-NiHiLo Care Services and Consultancy, Ex-NiHiLo, Care Services, Education Services",
};

export default Meta;

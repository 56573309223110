import React from "react";

const SectionThree = () => {
  return (
    <div className="container-fluid jof-student-1-sect-3">
      <div className="container">
        <div className="accordion" id="accordionPanelsStayOpenExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
              <button
                className="accordion-button jof-student-1-sect-3__btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                Two Days Per Week Attendance
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div className="accordion-body">
                <p className="j-paragraph-1">
                  We understand that starting a degree can be demanding,
                  especially when balancing work or family life. Our service is
                  designed so that your degree can fit perfectly into your
                  lifestyle. Most of our partners offer different timetables to
                  facilitate the balance between studies, work, and family. You
                  can either study 2 days per week, or during evenings and
                  weekends, and still be considered as full-time study!
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                className="accordion-button collapsed jof-student-1-sect-3__btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                Application Assistance
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo"
            >
              <div className="accordion-body">
                <p className="j-paragraph-1">
                  Once you have contacted us, one of our student advisors will
                  give you a call to learn more about you and your aspirations
                  for study. Depending on your location, work experience, and
                  your previous qualifications (if applicable), we will show you
                  every course option available to you. You are welcome to apply
                  to as many university courses as you want, which means you can
                  give yourself options and find the course that will perfectly
                  suit you. Once we have received all of the requested documents
                  for the application, we will submit your application for you
                  and
                </p>
                <p className="j-paragraph-1">
                  will keep you updated with any progress. You can either apply
                  with work experience (minimum of 2 years) for a Foundation
                  Degree, or Certificate of Higher Education (Level 4).
                  Alternatively, you can apply for a Bachelors degree, top-up,
                  or Masters with qualifications (minimum Level 2). Depending on
                  what qualifications and work experience you have our student
                  advisors will advise you on the best-suited degree program and
                  university in your desired university.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
              <button
                className="accordion-button collapsed jof-student-1-sect-3__btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseThree"
              >
                Interview Preparation
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree"
            >
              <div className="accordion-body">
                <p className="j-paragraph-1">
                  It’s a no brainer, the secret to achieving success is
                  preparing for an interview. We will guide you and help you
                  prepare for every interview and test you may need to complete
                  in order to gain admission to your course. We will give you
                  all the information and documentation needed to be fully aware
                  of the course and the university you are applying for. Once
                  you’re booked for an interview, one of our student advisors
                  will give you a call and practice with you in order to make
                  sure you are ready!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;

import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactToastify from "./components/ReactToastify";
import AnimatedCursor from "react-animated-cursor";
import Home from "./pages/home/Home";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import StudentOne from "./pages/student_one/StudentOne";
import StudentTwo from "./pages/student_two/StudentTwo";
import AboutUs from "./pages/about/AboutUs";
import ContactUs from "./pages/contact/ContactUs";
import Courses from "./pages/courses/Courses";
import Course from "./pages/course/Course";
import Admin from "./pages/admin/Admin";
import NotFound from "./components/NotFound";
import AllCourses from "./pages/courses/components/AllCourses";
import FilteredCourses from "./pages/courses/components/FilteredCourses";
import SearchedCourses from "./pages/courses/components/SearchedCourses";

function App() {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000, //300000ms equal to 5s
      },
    },
  });

  return (
    <div className="container-fluid p-0 mx-0">
      <QueryClientProvider client={client}>
        {/* <AnimatedCursor
          innerSize={12}
          trailingSpeed={12}
          color="84,170,1"
          outerStyle={{ zIndex: 9000 }}
        /> */}
        <ReactToastify />

        <Sidebar />

        <main>
          <Routes>
            <Route path="/admin/course/upload" element={<Admin />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/courses/:title" element={<Course />} />
            <Route path="/courses" element={<Courses />}>
              <Route path="/courses" element={<AllCourses />} />
              <Route path="/courses/filter-by" element={<FilteredCourses />} />
              <Route path="/courses/search" element={<SearchedCourses />} />
            </Route>
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/international-students" element={<StudentTwo />} />
            <Route path="/uk-eu-students" element={<StudentOne />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>

        <Footer />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;

import React from "react";
import ButtonLink from "../../../components/ButtonLink";

const SectionFour = () => {
  return (
    <div className="container-fluid jof-about-us-sect-4">
      <div className="container mb-5">
        <div className="row justify-content-center">
          <div className="card mb-3 jof-about-us-sect-4__card">
            <img
              src="img/nihilo-28.jpeg"
              className="card-img-top"
              alt="nihilo-28"
            />
            <div className="card-body">
              <p className="card-text j-paragraph-1">
                EX-NIHILO CARE SERVICES AND CONSULTANCY LIMITED
              </p>
              <p className="card-text j-paragraph-1">
                127 Nightingale Vale London SE18 4EL
              </p>
              <p className="card-text">
                <small className="fs-5">Our service is entirely free...</small>
              </p>
              <ButtonLink
                label="Get in touch"
                icon="bi bi-telephone"
                link="/contact-us"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;

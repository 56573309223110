import React from "react";

const USA = () => {
  return (
    <div className="jof-other-courses__unis">
      <h4 className="mb-5">USA</h4>
      <div className="jof-other-courses__unis-universities">
        <h5>USA Universities</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>SCHILLER INTERNATIONAL UNIVERSITY.</span> <br />
              <span>Tampa, Florida</span> <br />
            </div>
          </div>
        </div>
        <h5>Postgraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">
              MBA Masters of Business Administration
            </li>
            <li className="col-12 col-md-3">
              MA International Relation & Diplomacy
            </li>
            <li className="col-12 col-md-3">
              MBA Business Administration with Data science Certification
            </li>
          </ul>
        </div>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>The Univesity of Arizona</span> <br />
              <span>Tucson Campus.</span> <br />
            </div>
          </div>
        </div>
        <h5>Undergraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">BA Sociology</li>
            <li className="col-12 col-md-3">BA Creative Arts</li>
            <li className="col-12 col-md-3">BA History</li>
            <li className="col-12 col-md-3">BA Law</li>
            <li className="col-12 col-md-3">BSc Computer Science</li>
            <li className="col-12 col-md-3">BSc Microbiology</li>
            <li className="col-12 col-md-3">BSc Physics</li>
            <li className="col-12 col-md-3">BSc Aerospace Engineering</li>
            <li className="col-12 col-md-3">BSc Economics & Management</li>
            <li className="col-12 col-md-3">
              BSc Agricultural System Management
            </li>
            <li className="col-12 col-md-3">BSc Animal Science</li>
            <li className="col-12 col-md-3">BA Anthropology</li>
            <li className="col-12 col-md-3">BSc Applied Biotechnology</li>
            <li className="col-12 col-md-3">
              BSc Applied Computing: Software Development
            </li>
            <li className="col-12 col-md-3">
              BA Applied Humanities: Business Administration
            </li>
            <li className="col-12 col-md-3">BSc Applied Physics</li>
            <li className="col-12 col-md-3">BSc Architecture</li>
            <li className="col-12 col-md-3">BSc Bioinformatics</li>
            <li className="col-12 col-md-3">BSc Biology: Biomedical Science</li>
            <li className="col-12 col-md-3">BSc Business Administration</li>
            <li className="col-12 col-md-3">BSc Business Economics</li>
            <li className="col-12 col-md-3">BSc Care, Health & Society</li>
            <li className="col-12 col-md-3">BSc Chemistry</li>
            <li className="col-12 col-md-3">BSc Civil Engineering</li>
            <li className="col-12 col-md-3">BSc Criminal Justice Studies</li>
            <li className="col-12 col-md-3">
              BSc Applied Science Cyber Operations: Cyber Engineering
            </li>
            <li className="col-12 col-md-3">BA Economics</li>
            <li className="col-12 col-md-3">
              BSc Electric & Computer Engineering
            </li>
            <li className="col-12 col-md-3">BA English</li>
            <li className="col-12 col-md-3">BSc Administration Entrepreneur</li>
            <li className="col-12 col-md-3">BA Film & Television</li>
            <li className="col-12 col-md-3">BSc Business Admin (Finance)</li>
            <li className="col-12 col-md-3">BA Food Studies</li>
            <li className="col-12 col-md-3">BSc Game Design & Development</li>
            <li className="col-12 col-md-3">BA Global Culture</li>
            <li className="col-12 col-md-3">BA Information Science & Arts</li>
            <li className="col-12 col-md-3">
              BSc Information Science: Data Science
            </li>
            <li className="col-12 col-md-3">BSc Bus Admin (Management)</li>
            <li className="col-12 col-md-3">BSc Bus Admin (Marketing)</li>
            <li className="col-12 col-md-3">
              BSc Materials Science & Engineering
            </li>
            <li className="col-12 col-md-3">BSc Mathematics</li>
            <li className="col-12 col-md-3">BA Music</li>
            <li className="col-12 col-md-3">BSc Nursing</li>
            <li className="col-12 col-md-3">BA Political Science</li>
            <li className="col-12 col-md-3">BSc Public Health</li>
            <li className="col-12 col-md-3">BSc Public Admin & Management</li>
          </ul>
        </div>
        <h5>Postgraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">MA Chemistry</li>
            <li className="col-12 col-md-3">MA Communication</li>
            <li className="col-12 col-md-3">MA Counseling</li>
            <li className="col-12 col-md-3">MA Economics</li>
            <li className="col-12 col-md-3">MA Educational Policy</li>
            <li className="col-12 col-md-3">MA Education Psychology</li>
            <li className="col-12 col-md-3">MA English</li>
            <li className="col-12 col-md-3">MA Geography</li>
            <li className="col-12 col-md-3">MA Higher Education</li>
            <li className="col-12 col-md-3">MA History</li>
            <li className="col-12 col-md-3">MA Human Rights Practice</li>
            <li className="col-12 col-md-3">
              MA International Security Studies
            </li>
            <li className="col-12 col-md-3">MA Journalism</li>
            <li className="col-12 col-md-3">MA Mathematics</li>
            <li className="col-12 col-md-3">MA Philosophy</li>
            <li className="col-12 col-md-3">MA Psychology</li>
            <li className="col-12 col-md-3">MA Sociology</li>
            <li className="col-12 col-md-3">MA Spanish</li>
            <li className="col-12 col-md-3">MA Studies of Global Media</li>
            <li className="col-12 col-md-3">MA Teaching & Teacher Education</li>
            <li className="col-12 col-md-3">MA Teaching & Learning</li>
            <li className="col-12 col-md-3">MAR Architecture</li>
            <li className="col-12 col-md-3">MBA Business Admin</li>
            <li className="col-12 col-md-3">ME Engineering Mechanics</li>
            <li className="col-12 col-md-3">ME Aerospace Systems</li>
            <li className="col-12 col-md-3">ME System Engineering</li>
            <li className="col-12 col-md-3">
              MHM Masters in Healthcare Management
            </li>
            <li className="col-12 col-md-3">MM Music</li>
            <li className="col-12 col-md-3">MPA Public Admin</li>
            <li className="col-12 col-md-3">MPH Public Health</li>
            <li className="col-12 col-md-3">
              MPH Environmental & Occupational Health
            </li>
            <li className="col-12 col-md-3">MPP Public Policy</li>
            <li className="col-12 col-md-3">MSc Accounting</li>
            <li className="col-12 col-md-3">MS Agric & Resource Economics</li>
            <li className="col-12 col-md-3">
              MS Agric: Applied Econometrics & Data Analytics
            </li>
            <li className="col-12 col-md-3">MS Agric Education Research</li>
            <li className="col-12 col-md-3">
              MS Animal & Comparative Biomedical Services
            </li>
            <li className="col-12 col-md-3">MS Applied Mathematics</li>
            <li className="col-12 col-md-3">MS Architecture</li>
            <li className="col-12 col-md-3">MS Astronomy & AstroPhysics</li>
            <li className="col-12 col-md-3">MS Biochemistry</li>
            <li className="col-12 col-md-3">MS Biomedical Engineering</li>
            <li className="col-12 col-md-3">MS Biostatistics</li>
            <li className="col-12 col-md-3">
              MS Biosystem Analytics Technology
            </li>
            <li className="col-12 col-md-3">MS Business Analytics</li>
            <li className="col-12 col-md-3">
              MS Cellular & Molecular Medicine
            </li>
            <li className="col-12 col-md-3">MS Chemical Engineering</li>
            <li className="col-12 col-md-3">MS Chemistry</li>
            <li className="col-12 col-md-3">MS Clinical Research</li>
            <li className="col-12 col-md-3">
              MS Clinical Translational Science
            </li>
            <li className="col-12 col-md-3">MS Computer Studies</li>
            <li className="col-12 col-md-3">MS Cybersecurity</li>
            <li className="col-12 col-md-3">MS Data Science</li>
            <li className="col-12 col-md-3">MS Economics</li>
            <li className="col-12 col-md-3">
              MS Electrical & Computer Engineering
            </li>
            <li className="col-12 col-md-3">MS Etymology & Insect Science</li>
            <li className="col-12 col-md-3">MS Entrepreneurship</li>
            <li className="col-12 col-md-3">MS Environmental Engineering</li>
            <li className="col-12 col-md-3">MS Epidemiology</li>
            <li className="col-12 col-md-3">MS Finance</li>
            <li className="col-12 col-md-3">MS Genetics</li>
            <li className="col-12 col-md-3">
              MS Geographic Information System
            </li>
            <li className="col-12 col-md-3">MS Geoscience</li>
            <li className="col-12 col-md-3">MS Information Science</li>
            <li className="col-12 col-md-3">MS Marketing</li>
            <li className="col-12 col-md-3">
              MS Material Science & Engineering
            </li>
            <li className="col-12 col-md-3">MS Mathematics</li>
            <li className="col-12 col-md-3">MS Mechanical Engineering</li>
            <li className="col-12 col-md-3">MS Medical Pharmacology</li>
            <li className="col-12 col-md-3">MS Microbiology</li>
            <li className="col-12 col-md-3">
              MS Mining Geological/Geophysical Engineering
            </li>
            <li className="col-12 col-md-3">MS Molecular Medicine</li>
            <li className="col-12 col-md-3">MS Natural Resources</li>
            <li className="col-12 col-md-3">
              MS Nursing Clinical Systems Leadership
            </li>
            <li className="col-12 col-md-3">MS Nutritional Science</li>
            <li className="col-12 col-md-3">MS Optical Science</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default USA;

import React from "react";

const Spain = () => {
  return (
    <div className="jof-other-courses__unis">
      <h4 className="mb-5">Spain</h4>
      <div className="jof-other-courses__unis-universities">
        <h5>Spain Universities</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>SCHILLER INTERNATIONAL UNIVERSITY</span> <br />
              <span>Madrid campus, Spain.</span> <br />
            </div>
          </div>
        </div>
        <h5>Undergraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">BSc Computer Science</li>
            <li className="col-12 col-md-3">BSc International Business</li>
            <li className="col-12 col-md-3">
              BSc International Hospitality and Tourism Management.
            </li>
            <li className="col-12 col-md-3">
              BA International Relation and Diplomacy
            </li>
            <li className="col-12 col-md-3">BSc Business Analytics</li>
            <li className="col-12 col-md-3">BSc International Marketing</li>
          </ul>
        </div>
        <h5>Postgraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">
              MBA Masters of Business Administration
            </li>
            <li className="col-12 col-md-3">MBA International Business</li>
            <li className="col-12 col-md-3">MSc Data Analytics</li>
            <li className="col-12 col-md-3">MSc Sustainability</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Spain;

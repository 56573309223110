import React, { useState } from "react";
import { Link } from "react-router-dom";

const SearchCourse = () => {
  const [keyword, setKeyword] = useState("");

  return (
    <>
      <h3 className="j-heading-quatinary-black mb-2">Search course</h3>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="jof-courses-sect-3__search-form"
      >
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control jof-courses-sect-3__form-control"
            placeholder="Course name"
            aria-label="Recipient's username"
            aria-describedby="button-addon2"
            onChange={(e) => setKeyword(e.target.value)}
          />
          <button
            className="btn jof-courses-sect-3__form-btn"
            type="submit"
            id="button-addon2"
          >
            <Link to={`/courses/search?keyword=${keyword}`}>Search</Link>
          </button>
        </div>
      </form>
    </>
  );
};

export default SearchCourse;

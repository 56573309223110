import React from "react";
import SvgSpinner from "../../../components/svg/SvgSpinner";
import SvgAccessibility from "../../../components/svg/SvgAccessibility";

const SectionThree = () => {
  return (
    <div className="container-fluid jof-student-2-sect-3">
      <div className="container mb-5 jof-student-2-sect-3__container">
        <h3 className="jof-student-2-sect-3__header-main text-center">
          Studying abroad is one of the most unique experiences you can
          encounter in your life. It allows you to:
        </h3>
        <div className="row jof-student-2-sect-3__row justify-content-center">
          <div className="col-12 col-md-3 jof-student-2-sect-3__col jof-student-2-sect-3__col-1">
            Discover New Cultures
            <span className="jof-student-2-sect-3__icon">
              <SvgAccessibility width={128} height={128} color={"#bfbfc1"} />
            </span>
          </div>
          <div className="col-12 col-md-3 jof-student-2-sect-3__col jof-student-2-sect-3__col-2">
            Learn Multiple Languages And Transferable Skills
            <span className="jof-student-2-sect-3__icon">
              <SvgAccessibility width={128} height={128} color={"#bfbfc1"} />
            </span>
          </div>
          <div className="col-12 col-md-3 jof-student-2-sect-3__col jof-student-2-sect-3__col-3">
            Significantly Increase Your Professional Development
            <span className="jof-student-2-sect-3__icon">
              <SvgAccessibility width={128} height={128} color={"#bfbfc1"} />
            </span>
          </div>
        </div>
        <div className="row jof-student-2-sect-3__row justify-content-center">
          <div className="col-12 col-md-3 jof-student-2-sect-3__col jof-student-2-sect-3__col-4">
            Acquire New And Different Experiences
            <span className="jof-student-2-sect-3__icon">
              <SvgSpinner width={128} height={128} color={"#bfbfc1"} />
            </span>
          </div>
          <div className="col-12 col-md-3 jof-student-2-sect-3__col jof-student-2-sect-3__col-5">
            Enhance Your Employment Prospects
            <span className="jof-student-2-sect-3__icon">
              <SvgSpinner width={128} height={128} color={"#0e1820"} />
            </span>
          </div>
          <div className="col-12 col-md-3 jof-student-2-sect-3__col jof-student-2-sect-3__col-6">
            Take your career to the next level
            <span className="jof-student-2-sect-3__icon">
              <SvgSpinner width={128} height={128} color={"#808080"} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;

import React, { useState } from "react";
import { countries } from "../../../data/countries";
import UnitedKingdom from "./UnitedKingdom";
import Canada from "./Canada";
import Ireland from "./Ireland";
import USA from "./USA";
import Germany from "./Germany";
import France from "./France";
import Spain from "./Spain";
import Dubai from "./Dubai";

const OtherCourses = () => {
  const [currentSelection, setCurrentSelection] = useState("uk");

  const renderCurrentSelection = () => {
    switch (currentSelection) {
      case "uk":
        return <UnitedKingdom />;
      case "canada":
        return <Canada />;
      case "ireland":
        return <Ireland />;
      case "usa":
        return <USA />;
      case "germany":
        return <Germany />;
      case "france":
        return <France />;
      case "spain":
        return <Spain />;
      case "dubai":
        return <Dubai />;
      default:
        return null;
    }
  };

  return (
    <div className="container jof-other-courses">
      <h2 className="mb-5">Explore more Course</h2>
      <div className="jof-other-courses__countries">
        {countries.map((country) => (
          <div key={country.id}>
            <a
              href="#uni-display"
              onClick={() => setCurrentSelection(country.id)}
            >
              <img src={country.image} alt={country.name} />
              <div>
                <h3>{country.name}</h3>
              </div>
            </a>
          </div>
        ))}
      </div>
      <div id="uni-display">{renderCurrentSelection()}</div>
    </div>
  );
};

export default OtherCourses;

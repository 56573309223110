import React, { useState } from "react";
import ButtonLink from "../../../components/ButtonLink";

const SectionOne = () => {
  const [steps, setSteps] = useState([
    {
      id: 1,
      step: "STEP 1",
      content: "SELCET THE COUNTRY YOU WISH TO STUDY IN",
      active: "active",
    },
    {
      id: 2,
      step: "STEP 2",
      content: "SEND US AN INQUIRY WITH YOUR CONTACT DETAILS",
      active: "",
    },
    {
      id: 3,
      step: "STEP 3",
      content: "A STUDENT ADVISOR WILL CONTACT YOU",
      active: "",
    },
    {
      id: 4,
      step: "STEP 4",
      content: "WE WILL REQUEST DOCUMENT AND SUBMIT YOUR APPLICATION",
      active: "",
    },
  ]);

  const [currentStep, setCurrentStep] = useState({
    step: "STEP 1",
    content: "SELCET THE COUNTRY YOU WISH TO STUDY IN",
  });

  const handleStepClick = (id) => {
    // console.log(id);
    const dupSteps = [...steps];
    for (let i = 0; i < dupSteps.length; i++) {
      if (dupSteps[i].id === id) {
        dupSteps[i].active = "active";
        setCurrentStep({
          step: dupSteps[i].step,
          content: dupSteps[i].content,
        });
      } else {
        dupSteps[i].active = "";
      }
    }

    setSteps(dupSteps);
  };

  return (
    <div className="container-fluid jof-student-2-sect-1">
      <div className="container mb-5">
        <h2 className="j-heading-secondary-major jof-student-2-sect-1__heading-main">
          International Students
        </h2>
      </div>

      <div className="container jof-student-2-sect-1__container">
        <div className="card mb-3 jof-student-2-sect-1__card">
          <div className="row g-0 jof-student-2-sect-1__row">
            <div className="col-md-4">
              <img
                src="img/nihilo-21.jpg"
                className="img-fluid rounded-start"
                alt="Nihilo"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body jof-student-2-sect-1__card-body">
                <h5 className="card-title mt-2 mt-md-5 j-paragraph-3 fw-bold">
                  We can assist you to access Universities in the UK. We offer
                  support with Admissions, Visas, Accommodation, and partial
                  Scholarships (up to 30%).
                </h5>
                <p className="card-text j-paragraph-1">
                  We do not offer Full Scholarships to the UK. Our student
                  support team will guide help you select the best university to
                  suit your needs then proceed to help you gain an unconditional
                  offer letter for your chosen degree program. Followed by
                  supporting you through your visa application process to your
                  chosen destination.
                </p>
                <p className="card-text j-paragraph-1">
                  Applying as an international student to university is not an
                  easy job, this is what we are here for, to help you start
                  university in the UK or worldwide. We make the application
                  process SIMPLE.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container jof-student-2-sect-1__container">
        <div className="card mb-3 jof-student-2-sect-1__card">
          <div className="row g-0 jof-student-2-sect-1__row">
            <div className="col-md-4">
              <img
                src="img/nihilo-25.jpg"
                className="img-fluid rounded-start"
                alt="Nihilo"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body jof-student-2-sect-1__card-body">
                <h5 className="card-title mt-2 mt-md-5 j-paragraph-3 fw-bold">
                  Immigration and Visa Support
                </h5>
                <p className="card-text j-paragraph-1">
                  The latest immigration path will be available to foreign
                  students who have earned a degree at or above the
                  undergraduate level at a higher education institution with a
                  proven record of conformity.
                </p>
                <p className="card-text j-paragraph-1">
                  We support all of our international students to apply for
                  study visas worldwide with our partnered Universities. We
                  offer a professional team of experienced visa support services
                  to our clients. Qualified candidates on this path would be
                  eligible to study and work (in any field or at any level) or
                  pursue jobs in the United Kingdom for a maximum duration of
                  two years after completing their Degree Programmes.
                </p>
                <h3 className="j-paragraph-1 fw-bold">
                  We have a 100% success rate with visa applications.{" "}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-3">
        <h2 className="j-heading-quatinary-major">Application Process</h2>
      </div>
      <div className="container mb-5">
        <div className="card text-center jof-student-2-sect-1__card-2">
          <div className="card-header jof-student-2-sect-1__card-2-header">
            <ul className="nav nav-tabs card-header-tabs">
              {steps.map((step) => (
                <li className="nav-item" key={step.id}>
                  <span
                    className={`nav-link ${step.active}`}
                    aria-current="true"
                    onClick={() => handleStepClick(step.id)}
                  >
                    {step.step}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="card-body jof-student-2-sect-1__card-2-body">
            <h5 className="card-title j-paragraph-3 fw-bold mt-5">
              {currentStep.step}
            </h5>
            <p className="card-text j-paragraph-1 fw-bold text-primary">
              {currentStep.content}
            </p>
            <ButtonLink
              label="Get in touch"
              icon="bi bi-telephone"
              link="/contact-us"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;

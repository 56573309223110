import React from "react";
import Button from "../../../components/Button";

const SectionThree = ({ course }) => {
  const handleDownload = () => {
    // using Java Script method to get PDF file
    fetch("/pdf/artificial-intelligence-ai-data-specialist.pdf")
      .then((response) => {
        response
          .blob()
          .then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "artificial-intelligence-ai-data-specialist.pdf";
            alink.click();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="jof-course-sect-3">
      <div className="container jof-course-sect-3__container">
        <h3 className="j-heading-quatinary-black mb-4">About the course</h3>
        <p className="j-paragraph-1">{course?.about}</p>

        <div className="mt-5">
          <h3 className="j-heading-quatinary-black my-4">How to apply</h3>
          <p className="j-paragraph-1">
            From choosing your desired course, writing your personal statement
            and submitting the application, you can count on our friendly
            Recruitment Team for help and guidance at every step of the way. Get
            in touch to make an initial appointment to discuss your situation.
          </p>
          <p className="j-paragraph-1">
            Please complete the enquiry form below and one of our account
            managers will be in touch.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;

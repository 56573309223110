import React from "react";

const Dubai = () => {
  return (
    <div className="jof-other-courses__unis">
      <h4 className="mb-5">Dubai</h4>
      <div className="jof-other-courses__unis-universities">
        <h5>Dubai Universities</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>ROCHESTER INSTITUTE OF TECHNOLOGY.</span> <br />
              <span>RIT Dubai campus.</span> <br />
            </div>
          </div>
        </div>
        <h5>Undergraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">BSc Finance</li>
            <li className="col-12 col-md-3">BSc Mechanical Engineering</li>
            <li className="col-12 col-md-3">BSc Industrial Engineering</li>
            <li className="col-12 col-md-3">
              BSc Computer and Information Technology
            </li>
            <li className="col-12 col-md-3">BSc Computer Security</li>
            <li className="col-12 col-md-3">BSc Global Business Management</li>
          </ul>
        </div>
        <h5>Postgraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">ME Engineering Management</li>
            <li className="col-12 col-md-3">
              MSc Professional Studies. Data Analytics
            </li>
            <li className="col-12 col-md-3">
              MSc Service Leadership and Innovation
            </li>
            <li className="col-12 col-md-3">MSc Electrical Engineering</li>
            <li className="col-12 col-md-3">MSc Mechanical Engineering</li>
            <li className="col-12 col-md-3">MSc Computer Security</li>
            <li className="col-12 col-md-3">
              MSc Professional Studies Future Foresight and Planning
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dubai;

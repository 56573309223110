import React from "react";

const Canada = () => {
  return (
    <div className="jof-other-courses__unis">
      <h4 className="mb-5">Canada</h4>
      <div className="jof-other-courses__unis-universities">
        <h5>Canada Universities</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>ACADIA UNIVERSITY.</span> <br />
              <span>Wolfe, Canada.</span>
            </div>
          </div>
        </div>
        <h5>Undergraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">BA History</li>
            <li className="col-12 col-md-3">BSc Politics</li>
            <li className="col-12 col-md-3">BSc Biology</li>
            <li className="col-12 col-md-3">BSc Computer Science</li>
            <li className="col-12 col-md-3">BSc Economics</li>
            <li className="col-12 col-md-3">BSc Environmental Science</li>
            <li className="col-12 col-md-3">BSc Psychology</li>
            <li className="col-12 col-md-3">BSc Chemistry</li>
            <li className="col-12 col-md-3">BA Economics</li>
            <li className="col-12 col-md-3">BA English</li>
            <li className="col-12 col-md-3">BBA Business Administration</li>
            <li className="col-12 col-md-3">BA Canadian Studies</li>
            <li className="col-12 col-md-3">BCD Community Development</li>
            <li className="col-12 col-md-3">BA Classical Studies</li>
            <li className="col-12 col-md-3">BEd Education</li>
            <li className="col-12 col-md-3">
              BSc Engineering & Applied Science
            </li>
            <li className="col-12 col-md-3">
              BA Environmental Sustainability Studies
            </li>
            <li className="col-12 col-md-3">BSc Environmental Geoscience</li>
            <li className="col-12 col-md-3">BA French</li>
            <li className="col-12 col-md-3">BSc Geology</li>
            <li className="col-12 col-md-3">BSc Mathematics & Statistics</li>
          </ul>
        </div>

        <h5>Sprott Shaw College</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <p>
                Abbotsford, Chilliwack, East Vancouver, Kamloops, Kelowna, Maple
                Ridge, Nanaimo, New Westminster, Penticton, Richmond, School of
                Trades, Surrey, Seymour, Vancouver Downtown,
              </p>
            </div>
          </div>
        </div>
        <h5>Undergraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">Business Administration</li>
            <li className="col-12 col-md-3">Business Fundamental Program</li>
            <li className="col-12 col-md-3">Business Operation with Co-op</li>
            <li className="col-12 col-md-3">Hospitality Management</li>
            <li className="col-12 col-md-3">Advanced Business Management</li>
            <li className="col-12 col-md-3">Global Marketing Management</li>
            <li className="col-12 col-md-3">Cyber Security Program</li>
            <li className="col-12 col-md-3">Digital Marketing Management</li>
            <li className="col-12 col-md-3">Global Marketing Management</li>
            <li className="col-12 col-md-3">
              International Trade & Business Management Program
            </li>
            <li className="col-12 col-md-3">International Trade Program</li>
            <li className="col-12 col-md-3">
              Hospitality Industry Professional
            </li>
            <li className="col-12 col-md-3">
              Hospitality Operations Tourism/ Hospitality Management Program
            </li>
            <li className="col-12 col-md-3">Addictions Worker Program</li>
            <li className="col-12 col-md-3">
              Mental Health & Addictions Care Worker Program
            </li>
            <li className="col-12 col-md-3">
              Early Childhood Education Assistant Program
            </li>
            <li className="col-12 col-md-3">Education Assistant</li>
            <li className="col-12 col-md-3">Dental Assistant Program</li>
            <li className="col-12 col-md-3">Healthcare Assistant Program</li>
            <li className="col-12 col-md-3">Practical Nursing Program</li>
            <li className="col-12 col-md-3">Electrical Foundations</li>
            <li className="col-12 col-md-3">
              Computer Aided Design Technology
            </li>
            <li className="col-12 col-md-3">Paralegal Program</li>
            <li className="col-12 col-md-3">Veterinary Assistant Program</li>
          </ul>
        </div>
        <h5>Postgraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">
              Postgraduate Certificate in Nursing
            </li>
            <li className="col-12 col-md-3">
              Post Graduate Diploma in Global Business Management
            </li>
          </ul>
        </div>
        <h5>Others</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>HOLMES INSTITUTE CANADA.</span> <br />
              <span>Toronto, Canada. (DIPLOMA ONLY)</span> <br />
              <span>HOLMES INST. CANADA DIPLOMA COURSE</span> <br />
              <span>Diploma in Hospitality & Operations</span>
            </div>
          </div>
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>WEBA OSA SCHOOL</span> <br />
              <span>Kanata Campus. (DIPLOMA ONLY)</span> <br />
              <span>WEBA OSA SCHOOL(DIPLOMA)</span> <br />
              <span>Grade 11 Pathway Package</span> <br />
              <span>Grade 12 Pathway Package</span> <br />
              <span>English language Learners Program</span> <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Canada;

export function generateNumbers(pageNumbers) {
  let numbers = Array.from(Array(pageNumbers + 1).keys()).slice(1);
  let newNumbers = [];
  let counter = 0;
  const maxNumber = 8;

  const size = numbers.length;

  if (size > maxNumber) {
    // loop to select the last maxNumber pages
    for (let i = size; i > 0; i--) {
      counter = counter + 1; // count from 1 to max

      if (counter === maxNumber) {
        newNumbers.unshift(2); // make page 2 first item in array
        break; // exit loop
      }

      newNumbers.unshift(i);
    }

    return newNumbers; // array
  }

  return numbers; //array
}

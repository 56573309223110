import React from "react";

const SvgLocation = ({ color, width, height }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M8 0c-2.761 0-5 2.239-5 5 0 5 5 11 5 11s5-6 5-11c0-2.761-2.239-5-5-5zM8 8c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
      ></path>
    </svg>
  );
};

SvgLocation.defaultProps = {
  color: "#8081b7",
  width: 32,
  height: 32,
};

export default SvgLocation;

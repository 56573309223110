import React, { useState } from "react";

const SearchBox = () => {
  const [keyword, setKeyword] = useState("");

  return (
    <div className="j-home-header__search-box">
      <h4 className="j-heading-quatinary">Search</h4>
      <div className="j-home-header__search-box-search">
        <div>
          <input
            type="text"
            placeholder="course, university, city......."
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
        <div>
          <a
            href={`/courses/search?keyword=${keyword}`}
            className="j-home-header__search-box-link"
          >
            <i className="bi bi-search"></i>
          </a>
        </div>
      </div>
      <div className="j-home-header__search-box-btn">
        <button>Choose a study interest &gt;</button>
        <button>
          <a href="/courses" className="j-home-header__search-box-link">
            View all courses &gt;
          </a>{" "}
        </button>
      </div>
    </div>
  );
};

export default SearchBox;

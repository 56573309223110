import React, { useState, useRef, useEffect } from "react";
import ButtonLink from "./../../../components/ButtonLink";

const SectionFive = () => {
  const div_1Ref = useRef(null);

  const [inView, setInView] = useState(null);

  function handleScroll() {
    const bounding = div_1Ref.current.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    const showOnHeight = windowHeight / 5;

    // console.log(showOnHeight);
    // console.log(windowHeight);

    // console.log("bounding 1, top:", bounding.top);
    // console.log("bounding 1, bottom:", bounding.bottom);

    // Detect when element is fully visible in view port
    if (
      bounding.left >= 0 &&
      bounding.top <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    ) {
      // console.log("Element is in the viewport!");

      if (showOnHeight >= bounding.top && windowHeight < bounding.bottom) {
        setInView("show-div-1");
      } else if (
        showOnHeight <= bounding.top &&
        windowHeight < bounding.bottom
      ) {
        setInView("hide-div-1");
      } else if (windowHeight > bounding.bottom) {
        setInView("hide-div-1");
      }
    } else {
      // console.log("Element is NOT in the viewport!");
      setInView("hide-div-1");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`jof-student-1-sect-5`} ref={div_1Ref}>
      <div className={`jof-student-1-sect-5__div-1-imaginary`}>
        <h4 className="jof-student-1-sect-5__text-sub">Ex-Nihilo</h4>
        <h2 className="jof-student-1-sect-5__text-main j-heading-secondary-black">
          Government Funding By Student Finance
        </h2>
      </div>
      <div className={`jof-student-1-sect-5__div-1 ${inView}`}>
        <h4 className="jof-student-1-sect-5__text-sub">Ex-Nihilo</h4>
        <h2 className="jof-student-1-sect-5__text-main j-heading-secondary-black">
          Government Funding By Student Finance
        </h2>
      </div>

      <div className="jof-student-1-sect-5__div-2">
        <h3>THIS IS ONLY APPLICABLE TO UK RESIDENTS</h3>
        <p className="j-paragraph-1 u-margin-bottom-small">
          All courses that our partners offer are all entirely government-funded
          by Student Finance. If you are a UK resident, EU citizen working for
          at least 3 months in the UK, or have refugee status (indefinite leave
          to remain), you are eligible to receive funding from the UK
          Government. Depending on your circumstances, you could receive a
          tuition fee loan to cover your tuition fees and a maintenance loan and
          grant to help you with living costs, (varying from £9K-17K). As it’s a
          loan and grant, once you have finished your studies and start earning
          more than £25,500 per year salary (This figure changes please check
          the government website), Student Finance will ask you to start slowly
          paying finances back (usually less than £50 per month).
        </p>
        <p className="j-paragraph-1 u-margin-bottom-small">
          If you are applying for a Master’s Loan or a Doctoral Loan, you’ll
          only repay when your income is over £21,000 a year (before tax and
          other deductions).
        </p>
        <h3>FOR EU STUDENTS:</h3>
        <p className="j-paragraph-1 u-margin-bottom-small">
          As an EU resident applying to study in the UK you could be eligible to
          the tuition fee being funded by student finance. It is highly likely
          that after August 2021 this government funding scheme will stop, after
          August 2021 EU students will be classed as international students
          paying international fees.
        </p>
        <p className="j-paragraph-1 u-margin-bottom-small">
          If you from the EU and have arrived in the UK before 31st December
          2020 with a settled or pre-settled status, you could still be eligible
          to apply for Student Finance.
        </p>
        <ButtonLink
          label="Apply now"
          icon="bi bi-check-lg"
          link="/contact-us"
        />
      </div>
    </div>
  );
};

export default SectionFive;

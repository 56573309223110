import { Alert } from "bootstrap";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Loading from "../../../components/Loading";
import { useCourseUpload } from "./../../../services/hooks/useCourseUpload";

const UploadForm = () => {
  const { register, handleSubmit } = useForm();

  const {
    data: course,
    isLoading: isUploading,
    isSuccess,
    isError,
    error,
    mutate,
  } = useCourseUpload();

  const onSubmit = (data) => {
    const formData = { ...data };

    formData.apprenticeshipStandard = {
      title: data.apprenticeshipStandardTitle,
      url: data.apprenticeshipStandardURL,
    };

    formData.video = {
      url: data.video,
    };

    delete formData.apprenticeshipStandardTitle;
    delete formData.apprenticeshipStandardURL;

    // console.log(formData);

    mutate(formData);
  };

  if (isSuccess) {
    alert(course.message);
  }

  return (
    <div className="jof-contact-us-sect-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="jof-contact-us-sect-3__form"
            >
              <div className="jof-contact-us-sect-3__form-header">
                <h2>Input Course Data</h2>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("title")}
                />
                <label htmlFor="floatingInput">Course title</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("location")}
                />
                <label htmlFor="floatingInput">Location</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("studyMode")}
                />
                <label htmlFor="floatingInput">Study mode</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("startDates")}
                />
                <label htmlFor="floatingInput">Start dates</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("institution")}
                />
                <label htmlFor="floatingInput">Institution</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("levelOfStudy")}
                />
                <label htmlFor="floatingInput">Level of study</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("award")}
                />
                <label htmlFor="floatingInput">Award</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("apprenticeshipStandardTitle")}
                />
                <label htmlFor="floatingInput">
                  Apprenticeship standard title
                </label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("apprenticeshipStandardURL")}
                />
                <label htmlFor="floatingInput">
                  Apprenticeship standard URL
                </label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("apprenticeshipCertificate")}
                />
                <label htmlFor="floatingInput">
                  Apprenticeship Certificate
                </label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("fees")}
                />
                <label htmlFor="floatingInput">Fees</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("entryRequirements")}
                />
                <label htmlFor="floatingInput">Entry requirements</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("engLangRequirements")}
                />
                <label htmlFor="floatingInput">
                  English language requirements
                </label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("modeOfStudy")}
                />
                <label htmlFor="floatingInput">Mode of study</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("duration")}
                />
                <label htmlFor="floatingInput">Course duration</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("assessmentMethods")}
                />
                <label htmlFor="floatingInput">Assessment methods</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("locations")}
                />
                <label htmlFor="floatingInput">Course locations</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("scholarshipBursaries")}
                />
                <label htmlFor="floatingInput">Scholarship or bursaries</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("paymentPlan")}
                />
                <label htmlFor="floatingInput">Payment plan</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("studentFinance")}
                />
                <label htmlFor="floatingInput">Student finance</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  {...register("video")}
                />
                <label htmlFor="floatingInput">Course video URL</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  type="text"
                  className="form-control jof-admin__form-textarea"
                  id="floatingInput"
                  {...register("about")}
                ></textarea>
                <label htmlFor="floatingInput">About the course</label>
              </div>
              <div className="mb-3">
                <label className="form-check-label" htmlFor="floatingInput">
                  EU funding
                </label>
                <input
                  type="checkbox"
                  className="form-check-input jof-contact-us-sect-3__form-checkbox"
                  id="floatingInput"
                  style={{ height: "20px" }}
                  {...register("EUFunding")}
                />
              </div>
              {isError && (
                <p className="j-paragraph-1">{error.response?.data?.message}</p>
              )}
              <div className="text-center">
                {isUploading ? (
                  <Loading color={"white"} size="60" />
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg w-50 h-30"
                  >
                    Upload
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadForm;

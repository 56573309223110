import React from "react";

const SectionOne = ({ courseTitle }) => {
  return (
    <div className="jof-course-sect-1">
      <div className="jof-course-sect-1__text">
        <h2 className="j-heading-secondary-white">{courseTitle}</h2>
      </div>
    </div>
  );
};

export default SectionOne;

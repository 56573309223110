import React from "react";
import ButtonLink from "../../../components/ButtonLink";

const apiKey = process.env.REACT_APP_APIKey;

const SectionTwo = () => {
  return (
    <div className="j-home__sect-2 d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-between align-items-center j-home__sect-2-row">
          <div className="col-xs-12 col-md-6">
            <img
              src="img/nihilo-2.jpg"
              alt=""
              className="img-fluid rounded u-margin-bottom-medium j-home__sect-2-img"
            />
            <h2 className="j-heading-secondary-white u-margin-bottom-small j-home__sect-2-h2">
              UK AND EU STUDENTS
            </h2>
          </div>
          <div className="col-xs-12 col-md-5 j-home__sect-2-text">
            <p className="j-paragraph-1 u-margin-bottom-small">
              <span className="u-format-primary">Ex-NiHiLo</span> offers many
              different degree programs with 2 days per week attendance, fully
              government-funded by Student Finance. We work with universities in
              London, Birmingham, Leeds, Manchester, Newcastle, Greater Cardiff,
              Southampton. We can also connect you with online courses if this
              style of study suits your requirements.
            </p>
            <ul className="j-list j-home__sect-2-list">
              <li>
                <i className="bi bi-lightbulb-fill"></i>
                {"  "}Two Days Per Week Attendance
              </li>
              <li>
                <i className="bi bi-lightbulb-fill"></i>
                {"  "}Application Assistance
              </li>
              <li>
                <i className="bi bi-lightbulb-fill"></i>
                {"  "}Interview Preparation
              </li>
              <li>
                <i className="bi bi-lightbulb-fill"></i>
                {"  "}Career Guidance
              </li>
              <li>
                <i className="bi bi-lightbulb-fill"></i>
                {"  "}Government Funding By Student Finance
              </li>
            </ul>
            <ButtonLink
              label="Learn more"
              icon="bi bi-stars"
              link="/uk-eu-students"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;

import React from "react";

const ButtonLink = ({ label, icon, link }) => {
  return (
    <button className="j-btn-1 u-margin-top-small">
      <a href={`${link}`}>
        <span className="j-btn-1__text">{label}</span>{" "}
        <i className={`${icon}`}></i>
      </a>
    </button>
  );
};

ButtonLink.defaultProps = {
  label: "Get in touch",
  icon: "bi bi-telephone",
  link: "/contact-us",
};

export default ButtonLink;

import React from "react";

const UnitedKingdom = () => {
  return (
    <div className="jof-other-courses__unis">
      <h4 className="mb-5">United Kingdom</h4>
      <div className="jof-other-courses__unis-universities">
        <h5>UK Universities</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>ABERYSTWYTH UNIVERSITY.</span> <br />
              <span>Wales, UK</span>
            </div>
          </div>
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>BISHOP GROSSETESTE UNIVERSITY.</span> <br />
              <span>Lincoln, UK.</span>
            </div>
          </div>
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>UNIVERSITY OF WALES TRINITY SAINT DAVID.</span> <br />
              <span>London campus,</span> <br />
              <span>Birmingham Campus,</span> <br />
              <span>Swansea Campus.</span>
            </div>
          </div>
        </div>
        <h5>Undergraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">BA Business Data Science</li>
            <li className="col-12 col-md-3">BA Business TESOL</li>
            <li className="col-12 col-md-3">BA Business</li>
            <li className="col-12 col-md-3">BA Childhood Studies</li>
            <li className="col-12 col-md-3">BA Education Studies</li>
            <li className="col-12 col-md-3">BA TESOL & Linguistics</li>
            <li className="col-12 col-md-3">BA Drama</li>
            <li className="col-12 col-md-3">BA English & Drama</li>
            <li className="col-12 col-md-3">BA English & Literature</li>
            <li className="col-12 col-md-3">BA Music & Musicianship</li>
            <li className="col-12 col-md-3">BA Psychology & Applied Science</li>
            <li className="col-12 col-md-3">BA Sociology & Drama</li>
            <li className="col-12 col-md-3">BA English & Psychology</li>
            <li className="col-12 col-md-3">BA Health & Social Care</li>
            <li className="col-12 col-md-3">BA Psychology</li>
            <li className="col-12 col-md-3">BA Counseling</li>
            <li className="col-12 col-md-3">BA Psychology & Sociology</li>
            <li className="col-12 col-md-3">BA Psychology & Counseling</li>
            <li className="col-12 col-md-3">BA Sociology</li>
            <li className="col-12 col-md-3">BA Special Education</li>
            <li className="col-12 col-md-3">BA Sport & Mathematics</li>
            <li className="col-12 col-md-3">BA Psychology & Sport</li>
            <li className="col-12 col-md-3">BSc Applied Health & Wellbeing</li>
            <li className="col-12 col-md-3">BA Art History</li>
            <li className="col-12 col-md-3">BA Creative Performance</li>
            <li className="col-12 col-md-3">BA Creative Arts</li>
            <li className="col-12 col-md-3">BA Creative Writing</li>
            <li className="col-12 col-md-3">BA Drama & Theatre</li>
            <li className="col-12 col-md-3">BA Education</li>
            <li className="col-12 col-md-3">BA English Literature & Drama</li>
            <li className="col-12 col-md-3">
              BA English Literature & Education
            </li>
            <li className="col-12 col-md-3">BA English Studies & TESOL</li>
            <li className="col-12 col-md-3">LLB Business Law</li>
            <li className="col-12 col-md-3">BA Film & Television Studies</li>
            <li className="col-12 col-md-3">BA Filmmaking</li>
            <li className="col-12 col-md-3">BA Fine Arts</li>
            <li className="col-12 col-md-3">LLB Criminal Law</li>
            <li className="col-12 col-md-3">BA History</li>
            <li className="col-12 col-md-3">BA International Relations</li>
            <li className="col-12 col-md-3">BA Law</li>
            <li className="col-12 col-md-3">BA Media & Communication</li>
            <li className="col-12 col-md-3">BA Media & Creative Writing</li>
            <li className="col-12 col-md-3">BEng Engineering Physics</li>
            <li className="col-12 col-md-3">BA Media & Theatre Studies</li>
            <li className="col-12 col-md-3">BA Politics</li>
            <li className="col-12 col-md-3">
              BA Politics & International Relations
            </li>
            <li className="col-12 col-md-3">BA Politics & Modern History</li>
            <li className="col-12 col-md-3">BA Strategy</li>
            <li className="col-12 col-md-3">BSc Accounting & Finance</li>
            <li className="col-12 col-md-3">BSc Agriculture</li>
            <li className="col-12 col-md-3">BSc Animal Behaviour</li>
            <li className="col-12 col-md-3">BSc Animal Science</li>
            <li className="col-12 col-md-3">LLB Human Rights</li>
            <li className="col-12 col-md-3">BSc Artificial Intelligence</li>
            <li className="col-12 col-md-3">BSc Biochemistry</li>
            <li className="col-12 col-md-3">BSc Biology</li>
            <li className="col-12 col-md-3">BSc Business & Climate Change</li>
            <li className="col-12 col-md-3">LLB Law</li>
            <li className="col-12 col-md-3">BSc Business & Management</li>
            <li className="col-12 col-md-3">LLB Law & Criminology</li>
            <li className="col-12 col-md-3">LLB Law & Business Management</li>
            <li className="col-12 col-md-3">
              BSc Law & International Relations
            </li>
            <li className="col-12 col-md-3">BSc Business & Economics</li>
            <li className="col-12 col-md-3">BSc Business Finance</li>
            <li className="col-12 col-md-3">
              BSc Business Information Technology
            </li>
            <li className="col-12 col-md-3">BSc Computer Graphics & Visuals</li>
            <li className="col-12 col-md-3">BSc Computer Science</li>
            <li className="col-12 col-md-3">BSc Criminology</li>
            <li className="col-12 col-md-3">BSc Environmental Science</li>
            <li className="col-12 col-md-3">BSc Digital Marketing</li>
            <li className="col-12 col-md-3">BSc Data Science</li>
            <li className="col-12 col-md-3">BSc Economics</li>
            <li className="col-12 col-md-3">BSc Financial Mathematics</li>
            <li className="col-12 col-md-3">BSc Genetics & Biochemistry</li>
          </ul>
        </div>
        <h5>Postgraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">MA Heritage Education</li>
            <li className="col-12 col-md-3">MA Social & Cultural History</li>
            <li className="col-12 col-md-3">MA Theology & Religious Studies</li>
            <li className="col-12 col-md-3">MSc International Business</li>
            <li className="col-12 col-md-3">MA Education & TESOL</li>
            <li className="col-12 col-md-3">MA English Literature</li>
            <li className="col-12 col-md-3">MSc Advanced Computer Science</li>
            <li className="col-12 col-md-3">MSc Animal Science</li>
            <li className="col-12 col-md-3">MSc Artificial Intelligence</li>
            <li className="col-12 col-md-3">MA Arts History</li>
            <li className="col-12 col-md-3">MSc Behaviour Change</li>
            <li className="col-12 col-md-3">MSc Biotechnology</li>
            <li className="col-12 col-md-3">MSc Computer Science</li>
            <li className="col-12 col-md-3">MA Creative Writing</li>
            <li className="col-12 col-md-3">
              MBA Corporate Social Responsibility
            </li>
            <li className="col-12 col-md-3">MPhil Criminology</li>
            <li className="col-12 col-md-3">MSc Data Science</li>
            <li className="col-12 col-md-3">MA Documentary Filmmaking</li>
            <li className="col-12 col-md-3">MSc Environmental Change</li>
            <li className="col-12 col-md-3">MSc Equine Science</li>
            <li className="col-12 col-md-3">MBA Engineering Management</li>
            <li className="col-12 col-md-3">MPhil European Languages</li>
            <li className="col-12 col-md-3">MA Film Producing</li>
            <li className="col-12 col-md-3">MA Fine Arts</li>
            <li className="col-12 col-md-3">MSc Finance</li>
            <li className="col-12 col-md-3">
              MBA Global Supply Chain Management
            </li>
            <li className="col-12 col-md-3">MA History of Wales</li>
            <li className="col-12 col-md-3">
              MA Information & Library Science
            </li>
            <li className="col-12 col-md-3">MA International Relation</li>
            <li className="col-12 col-md-3">
              MSc International Business Management
            </li>
            <li className="col-12 col-md-3">MSc International Finance</li>
            <li className="col-12 col-md-3">MBA International Marketing</li>
            <li className="col-12 col-md-3">MPhil International Politics</li>
            <li className="col-12 col-md-3">MA Literary Studies</li>
            <li className="col-12 col-md-3">MSc Livestock Science</li>
            <li className="col-12 col-md-3">MPhil Law</li>
            <li className="col-12 col-md-3">Msc Management & Finance</li>
            <li className="col-12 col-md-3">MA Modern History</li>
            <li className="col-12 col-md-3">ExecMBA Management</li>
            <li className="col-12 col-md-3">MBA Masters of Business Admin.</li>
            <li className="col-12 col-md-3">MA Professional Translation</li>
            <li className="col-12 col-md-3">MBA Project Management</li>
            <li className="col-12 col-md-3">MPhil Psychology</li>
            <li className="col-12 col-md-3">MSc Radio Spectrum Engineering</li>
            <li className="col-12 col-md-3">MSc Remote Sensing & GIS</li>
            <li className="col-12 col-md-3">MA Society, Space & Place</li>
            <li className="col-12 col-md-3">MSc Statistics for Computation</li>
            <li className="col-12 col-md-3">
              MPhil Theatre, Film & Television
            </li>
            <li className="col-12 col-md-3">MSc Youth Justice</li>
            <li className="col-12 col-md-3">MRes Animal Science</li>
            <li className="col-12 col-md-3">MRes Bioscience</li>
            <li className="col-12 col-md-3">MRes Parasite Control</li>
            <li className="col-12 col-md-3">
              MSc Digital Information & Technology
            </li>
            <li className="col-12 col-md-3">MA History & Heritage</li>
            <li className="col-12 col-md-3">MA Medieval Britain & Europe</li>
            <li className="col-12 col-md-3">MA Education</li>
            <li className="col-12 col-md-3">MA Education Leadership</li>
            <li className="col-12 col-md-3">
              MA Archives & Records Management
            </li>
            <li className="col-12 col-md-3">MA Chemistry</li>
            <li className="col-12 col-md-3">MA Communications</li>
            <li className="col-12 col-md-3">MA Economics</li>
            <li className="col-12 col-md-3">MA Education Policy</li>
            <li className="col-12 col-md-3">MA Education Psychology</li>
            <li className="col-12 col-md-3">MA English</li>
            <li className="col-12 col-md-3">MA Geography</li>
            <li className="col-12 col-md-3">MA Higher Education</li>
            <li className="col-12 col-md-3">MA History</li>
            <li className="col-12 col-md-3">MA Human Rights Practices</li>
            <li className="col-12 col-md-3">
              MA International Security Studies
            </li>
            <li className="col-12 col-md-3">MA Journalism</li>
            <li className="col-12 col-md-3">MA Mathematics</li>
            <li className="col-12 col-md-3">MA Philosophy</li>
            <li className="col-12 col-md-3">MA Psychology</li>
            <li className="col-12 col-md-3">MA Sociology</li>
            <li className="col-12 col-md-3">MA Studies of Global Media</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UnitedKingdom;

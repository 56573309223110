import React from "react";

const SvgAsk = ({ color, width, height }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 504 504"
    >
      <g>
        <g>
          <polygon points="148,305.6 164.8,303.6 168.8,243.2 		" />
        </g>
      </g>

      <path
        fill={color}
        d="M377.6,0H126C56.8,0,0,56.8,0,126.4V378c0,69.2,56.8,126,126,126h251.6c69.6,0,126.4-56.8,126.4-126.4V126.4
			C504,56.8,447.2,0,377.6,0z M192.4,360.4l-20,3.2l-7.6,1.2l-0.8-7.2v-26.8l-24.8,3.6l-12.8,34.4l-0.8,3.2l-3.2,0.8l-17.2,2.8
			L94.8,378l82-204.4l20-3.2l0.4,184.4l0.4,5.2L192.4,360.4z M272,268.4c15.2,15.6,22.8,26.8,18.8,44.8
			c-4.4,19.2-29.6,35.2-50.8,38.4c-9.6,2-18.4,2-24,0.4l-9.6-2.4l6-7.6l8.8-10.8l2-2.8l3.6,1.2c3.2,0.8,6.8,0.8,12.4,0
			c10.8-1.6,19.6-8.4,20.8-14c2-8.4-1.6-14.8-14.8-29.6c-12.8-14.4-16-22.8-13.6-34.8c3.2-14.8,22-27.6,42.4-28.4
			c11.6-0.8,16,0.8,20.8,2.4l9.6,2.8l-6.4,7.2l-8,8.4l-2.4,2.8l-3.6-1.2c-2.4-0.8-5.2-1.2-10.8-1.2c-6.4,0.4-11.2,4-11.6,6.8
			C260.4,253.2,260,256,272,268.4z M377.6,328.8l-6.8,0.8l-19.2,2.8l-5.2,0.8l-2-5.2l-7.2-26.4l-6.4,30l-1.2,4l-4,0.4l-18.8,2.8
			l-8.4,1.2l2-8.4L344,126.4l30.8,1.6l-24,116l21.2-22.8l1.6-0.8h2l17.2-3.6l17.2-3.2L359.2,268L377.6,328.8z"
      />
    </svg>
  );
};

SvgAsk.defaultProps = {
  color: "#8081b7",
  width: 32,
  height: 32,
};

export default SvgAsk;

import React from "react";
import Header from "./Header";
import { ColorRing } from "react-loader-spinner";
import Meta from "./Meta";

const FullPageSpinner = () => {
  return (
    <div className="jof-full-page-spinner">
      <Meta title={"Loading..."} />
      <Header />
      <div className="jof-full-page-spinner__content">
        <ColorRing
          visible={true}
          height="100"
          width="100"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["white", "white", "white", "white", "white"]}
        />
      </div>
    </div>
  );
};

export default FullPageSpinner;

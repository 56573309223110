import React from "react";

const SectionOne = () => {
  return (
    <div className="container-fluid jof-student-1-sect-1">
      <h2 className="j-heading-secondary-major jof-student-1-sect-1__header-main">
        Ex-Nihilo Education
      </h2>
      <div className="jof-student-1-sect-1__container">
        <h2 className="jof-student-1-sect-1__header-lg mb-4">
          Student Finance Available
        </h2>
        <div className="jof-student-1-sect-1__img-box">
          <img className="img-fluid" src="img/nihilo-13.jpg" alt="nihilo-13" />
        </div>
        <a href="/contact-us">
          <h2 className="jof-student-1-sect-1__header-absolute">
            100% Free Service
          </h2>
        </a>

        <h2 className="jof-student-1-sect-1__header">
          Student Finance Available
        </h2>
        <div className="jof-student-1-sect-1__text-box">
          <p className="j-paragraph-1">
            Ex-NiHiLo offer many different degree programs with 2 days a week
            attendance, fully government-funded by Student Finance. We can also
            connect you with online courses if this style of study suits your
            requirements. We will help you with the full application process
            from start until you are registered. Ex-NiHiLo can help you to
            connect to a wide range of foundation up to master’s degree courses
            and will help you find the perfect study path for your chosen
            degree. Our team will guide you through the whole process by
            submitting your application and preparing you for every interview or
            test that may be required for attendance. We can also assist you
            with your Student Finance application!
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;

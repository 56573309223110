import React from "react";
import ButtonLink from "../../../components/ButtonLink";

const SectionThree = () => {
  return (
    <div className="j-home__sect-3 d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-between align-items-center j-home__sect-3-row">
          <div className="col-xs-12 col-md-6">
            <img
              src="img/nihilo-3.jpg"
              alt=""
              className="img-fluid rounded u-margin-bottom-medium j-home__sect-3-img"
            />
            <h2 className="j-heading-secondary-black u-margin-bottom-small j-home__sect-3-h2">
              INTERNATIONAL STUDENTS
            </h2>
          </div>
          <div className="col-xs-12 col-md-5 j-home__sect-3-text">
            <p className="j-paragraph-1 u-margin-bottom-small">
              For international students, our service is entirely free!{" "}
              <span className="u-format-primary">Ex-NiHiLo</span> is ready to
              help you with admission to your chosen course, scholarships, visa
              applications, and accommodation. We offer the easiest and friendly
              path to securing your study abroad. The desire to study abroad
              usually comes with a lot of work and stress, but with{" "}
              <span className="u-format-primary">Ex-NiHiLo</span> we can help
              you through the full application process!
            </p>
            <ul className="j-list j-home__sect-3-list">
              <li>
                <i className="bi bi-lightbulb-fill"></i>
                {"  "}University Application Support
              </li>
              <li>
                <i className="bi bi-lightbulb-fill"></i>
                {"  "}Freedom of thought and expression
              </li>
              <li>
                <i className="bi bi-lightbulb-fill"></i>
                {"  "}Scholarship Assistance
              </li>
            </ul>
            <ButtonLink
              label="Learn more"
              icon="bi bi-stars"
              link="/international-students"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;

import React from "react";
import Header from "../../components/Header";
import Carousel from "../../components/Carousel";
import SectionOne from "./components/SectionOne";
import SectionTwo from "./components/SectionTwo";
import SectionThree from "./components/SectionThree";
import Meta from "../../components/Meta";

const titleOne = "International Students";
const titleTwo = "International Students";
const titleThree = "International Students";
const descriptionOne = `We assist you in discovering your desired course in UK universities`;
const descriptionTwo = `We offer support with Admissions, Visas, Accommodation, and partial Scholarships`;
const descriptionThree = `Students who choose to study abroad gain experiences that they’ll remember for a lifetime`;

const StudentTwo = () => {
  return (
    <>
      <Meta
        title={
          "International Students Support | Ex-Nihilo Care Services and Consultancy"
        }
      />
      <Header />
      <Carousel
        carouselType={""}
        itemOne={"jof-carousel-1__item-4"}
        itemTwo={"jof-carousel-1__item-5"}
        itemThree={"jof-carousel-1__item-6"}
        titleOne={titleOne}
        titleTwo={titleTwo}
        titleThree={titleThree}
        descriptionOne={descriptionOne}
        descriptionTwo={descriptionTwo}
        descriptionThree={descriptionThree}
      />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
    </>
  );
};

export default StudentTwo;

import http from "./httpService";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export async function generalEnquiry(user) {
  const { data } = await http.post(
    "/enquiries/general",
    {
      fullName: user.fullName,
      email: user.email,
      telephone: user.telephone,
      studentType: user.studentType,
      currentLocation: user.currentLocation,
      additionalNotes: user.additionalNotes,
    },
    config
  );
  return data;
}

export async function courseEnquiry(user) {
  const { data } = await http.post(
    "/enquiries/course",
    {
      fullName: user.fullName,
      email: user.email,
      telephone: user.telephone,
      city: user.city,
      country: user.country,
      course: user.course,
      startTime: user.startTime,
      additionalNotes: user.additionalNotes,
    },
    config
  );
  return data;
}

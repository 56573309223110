import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="j-footer d-flex justify-content-center align-items-center">
      <div className="container j-footer__container">
        <div className="row justify-content-center align-items-center">
          <div className="col-xs-12 col-md-6 ps-3">
            <div className="row u-margin-bottom-large">
              <h3 className="j-heading-quatinary-white">
                Take your education to the next level
              </h3>
            </div>
            <div className="row justify-content-center align-items-center u-margin-bottom-medium">
              <div className="j-footer__icons">
                <a
                  href="https://instagram.com/ex_nihiloeducationconsult?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                </a>
                <a
                  href="https://www.facebook.com/Ex.nihiloeducationconsult?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-facebook"></i>
                </a>
                <a
                  href="https://twitter.com/Ex_NiHiLoCons?s=09"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-twitter"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 ps-3">
            <div className="row j-footer__contact">
              <h4 className="j-heading-quatinary-small-white u-margin-bottom-medium">
                GET IN TOUCH
              </h4>
              <span className="u-margin-bottom-small jof-footer__mail">
                <a href="mailto: info@ex-nihilocareservicesandconsultancy.com">
                  {" "}
                  info@ex-nihilocareservicesandconsultancy.com
                </a>
              </span>
              <span>
                <a
                  href="https://wa.link/4ika3a"
                  target="_blank"
                  rel="noreferrer"
                >
                  07511187493
                </a>
              </span>
            </div>
            <div className="row">
              <h4 className="j-heading-quatinary-small-white u-margin-top-large u-margin-bottom-medium">
                EXPLORE
              </h4>
              <div className="j-footer__nav u-margin-bottom-medium">
                <NavLink to="/uk-eu-students" className="j-footer__link">
                  UK & EU Students
                </NavLink>
                <NavLink
                  to="/international-students"
                  className="j-footer__link"
                >
                  International students
                </NavLink>
              </div>
            </div>
            <div className="row j-footer__copyright">
              © 2022 EX-NIHILO CARE SERVICES & CONSULTANCY | All Rights
              Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";

const France = () => {
  return (
    <div className="jof-other-courses__unis">
      <h4 className="mb-5">France</h4>
      <div className="jof-other-courses__unis-universities">
        <h5>France Universities</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>SCHILLER INTERNATIONAL UNIVERSITY</span> <br />
              <span>Paris Campus, Franc</span> <br />
            </div>
          </div>
        </div>
        <h5>Undergraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">BSc Computer Science</li>
            <li className="col-12 col-md-3">BSc International Business</li>
            <li className="col-12 col-md-3">
              BA International Relation and Diplomacy
            </li>
            <li className="col-12 col-md-3">BSc Business Analytics</li>
            <li className="col-12 col-md-3">BSc International Marketing</li>
          </ul>
        </div>
        <h5>Postgraduate Courses</h5>
        <div className="jof-other-courses__courses">
          <ul className="row">
            <li className="col-12 col-md-3">
              MBA Masters of Business of Administration
            </li>
            <li className="col-12 col-md-3">MBA International Business</li>
            <li className="col-12 col-md-3">
              MA International Relation and Diplomacy
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default France;

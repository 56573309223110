import http from "./httpService";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export async function uploadCourse(course) {
  const { data } = await http.post(
    "/courses",
    {
      title: course.title,
      location: course.location,
      studyMode: course.studyMode,
      startDates: course.startDates,
      institution: course.institution,
      levelOfStudy: course.levelOfStudy,
      award: course.award,
      apprenticeshipStandard: course.apprenticeshipStandard,
      apprenticeshipCertificate: course.apprenticeshipCertificate,
      fees: course.fees,
      entryRequirements: course.entryRequirements,
      engLangRequirements: course.engLangRequirements,
      modeOfStudy: course.modeOfStudy,
      duration: course.duration,
      assessmentMethods: course.assessmentMethods,
      locations: course.locations,
      scholarshipBursaries: course.scholarshipBursaries,
      paymentPlan: course.paymentPlan,
      studentFinance: course.studentFinance,
      video: course.video,
      about: course.about,
      EUFunding: course.EUFunding,
    },
    config
  );
  return data;
}

export async function getCourses(currentPage) {
  const { data } = await http.get(`/courses?pageNumber=${currentPage}`);
  return data;
}

export async function getCourse(title, id) {
  const { data } = await http.get(`/courses/${title}?id=${id}`);
  return data;
}

export async function filterCourse({ field, selection, page }) {
  const { data } = await http.get(
    `/courses/filterby/${field}?selection=${selection}&pageNumber=${page}`
  );
  return data;
}

export async function searchCourse({ keyword, page }) {
  const { data } = await http.get(
    `/courses/search?keyword=${keyword}&pageNumber=${page}`
  );
  return data;
}

import React, { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import Header from "../../components/Header";
import SectionOne from "./components/SectionOne";
import SectionTwo from "./components/SectionTwo";
// import { courses } from "../../data/courses";
import SectionThree from "./components/SectionThree";
import EnquiryForm from "./components/EnquiryForm";
import { useCourse } from "./../../services/hooks/useCourse";
import FullPageSpinner from "../../components/FullPageSpinner";
import ErrorPage from "../../components/ErrorPage";
import Meta from "../../components/Meta";

const Course = () => {
  const { title } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data: course, isLoading, isError, error } = useCourse(title, id);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <Meta title={"Course | Ex-Nihilo Care Services and Consultancy"} />
      <Header />
      <SectionOne courseTitle={course?.title} />
      <SectionTwo course={course} />
      <SectionThree course={course} />
      <EnquiryForm />
    </>
  );
};

export default Course;

import React from "react";
import Header from "../../components/Header";
import Carousel from "../../components/Carousel";
import SectionOne from "./components/SectionOne";
import SectionTwo from "./components/SectionTwo";
import SectionThree from "./components/SectionThree";
import SectionFour from "./components/SectionFour";
import SectionFive from "./components/SectionFive";
import Meta from "../../components/Meta";

const titleOne = "UK & EU Student Support";
const titleTwo = "UK & EU Student Support";
const titleThree = "UK & EU Student Support";
const descriptionOne = `Ex-Nihilo will help you find the perfect study path for your chosen degree`;
const descriptionTwo = `We can also assist you with your Student Finance application!`;
const descriptionThree = ` Our service is designed so that your degree can fit perfectly into your lifestyle`;

const StudentOne = () => {
  return (
    <>
      <Meta title={"UK & EU Students"} />
      <Header />
      <Carousel
        carouselType={"carousel-fade"}
        itemOne={"jof-carousel-1__item-1"}
        itemTwo={"jof-carousel-1__item-2"}
        itemThree={"jof-carousel-1__item-3"}
        titleOne={titleOne}
        titleTwo={titleTwo}
        titleThree={titleThree}
        descriptionOne={descriptionOne}
        descriptionTwo={descriptionTwo}
        descriptionThree={descriptionThree}
      />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
    </>
  );
};

export default StudentOne;

import React from "react";

const SectionFive = () => {
  return (
    <div className="j-home__sect-5">
      <div className="container">
        <h2 className="j-heading-secondary-black u-margin-bottom-small mt-3 text-center">
          LATEST NEWS
        </h2>
        <p className="j-paragraph-1 text-center u-margin-bottom-medium">
          What's new in the university?
        </p>
        <div className="card mb-3 j-home__sect-5-card">
          <div className="row g-0 j-home__sect-5-row">
            <div className="col-md-4">
              <img
                src="img/nihilo-7.jpg"
                className="img-fluid rounded-start h-100"
                alt="Nihilo"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body j-home__sect-5-card-body">
                <h3 className="card-title mt-2 mt-md-4 j-home__sect-5-card-title">
                  ENSURING A GREAT STUDENT EXPERIENCE
                </h3>
                <p className="card-text j-paragraph-1 mt-3">
                  Tips for Enhancing Student Experience in Higher Education:
                </p>
                {/* <p className="card-text j-paragraph-1 mt-2">
                  Implement Creative Initiatives, Offer Alumni Engagement
                  Opportunities, Provide Online Forums, Embrace Diversity, Ask
                  for Feedback and Apply It, Offer Training Opportunities, Offer
                  Extra Support Services and Promote Them, Work Closer With the
                  Students’ Union.
                </p> */}
                <ul className="card-text j-paragraph-1 mt-2">
                  <li>Implement Creative Initiatives.</li>
                  <li>Offer Alumni Engagement Opportunities.</li>
                  <li>Provide Online Forums, Embrace Diversity.</li>
                  <li>Ask for Feedback and Apply It.</li>
                  <li>Offer Extra Support Services and Promote Them.</li>
                  <li>Work Closer With the Students’ Union.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-3 j-home__sect-5-card my-5">
          <div className="row g-0 j-home__sect-5-row">
            <div className="col-md-4">
              <img
                src="img/nihilo-8.jpg"
                className="img-fluid rounded-start h-100"
                alt="Nihilo"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body j-home__sect-5-card-body">
                <h3 className="card-title mt-2 mt-md-4 j-home__sect-5-card-title">
                  GETTING READY FOR THE UNIVERSITY
                </h3>
                <p className="card-text j-paragraph-1 mt-3">
                  Tips to get you ready for University:
                </p>
                {/* <p className="card-text j-paragraph-1 mt-2">
                  1. Learn to cook – if you can’t already! … 2. If you’re an
                  international student – join an orientation program. … 3.
                  Consider setting a student budget. … 4. Make sure you’re
                  organized. … 5. Get ahead with your reading. … 6. Use social
                  media to get chatting with your new course-mates. … 7. Get
                  packing!
                </p> */}
                <ul className="card-text j-paragraph-1 mt-2">
                  <li>Learn to cook – if you can’t already!</li>
                  <li>
                    If you’re an international student – join an orientation
                    program.
                  </li>
                  <li>Consider setting a student budget.</li>
                  <li>Make sure you’re organized.</li>
                  <li>Get ahead with your reading.</li>
                  <li>
                    Use social media to get chatting with your new course-mates.
                  </li>
                  <li>Get packing!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="card-group">
          <div className="card j-home__sect-5-card">
            <img
              src="img/nihilo-7.jpg"
              className="card-img-top j-home__sect-5-img"
              alt="nihilo-7"
            />
            <div className="card-body j-home__sect-5-card-body">
              <h3 className="j-heading-quatinary-black">
                ENSURING A GREAT STUDENT EXPERIENCE
              </h3>
              <p className="card-text">
                Tips for Enhancing Student Experience in Higher Education
              </p>
            </div>
            <div className="card-footer">
              <small className="j-home__sect-5-read-more">
                {" "}
                <a href="/">Read more...</a>
              </small>
            </div>
          </div>
          <div className="card j-home__sect-5-card">
            <img
              src="img/nihilo-8.jpg"
              className="card-img-top j-home__sect-5-img"
              alt="nihilo-8"
            />
            <div className="card-body j-home__sect-5-card-body">
              <h3 className="j-heading-quatinary-black">
                GETTING READY FOR THE UNIVERSITY
              </h3>
              <p className="card-text">
                Best tips to get you ready for university
              </p>
            </div>
            <div className="card-footer">
              <small className="j-home__sect-5-read-more">
                {" "}
                <a href="/">Read more...</a>
              </small>
            </div>
          </div>
          <div className="card j-home__sect-5-card">
            <img
              src="img/nihilo-9.jpg"
              className="card-img-top j-home__sect-5-img"
              alt="nihilo-9"
            />
            <div className="card-body j-home__sect-5-card-body">
              <h3 className="j-heading-quatinary-black">
                STUDENTS SEEKING INTERNATIONAL DEGREE
              </h3>
              <p className="card-text">
                The best subjects for international degrees are Engineering,
                Art, Fine Arts, Public Health
              </p>
            </div>
            <div className="card-footer">
              <small className="j-home__sect-5-read-more">
                <a href="/">Read more...</a>
              </small>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SectionFive;

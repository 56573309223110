import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGeneralEnquiry } from "../../../services/hooks/useGeneralEnquiry";
import Loading from "../../../components/Loading";

const schema = yup.object().shape({
  fullName: yup
    .string()
    .required("Your full name is required")
    .label("Full name"),
  email: yup.string().email().required("Email is required").label("Email"),
  telephone: yup
    .string("Input a valid phone number")
    .required("Phone number is required")
    .label("Telephone"),
  studentType: yup
    .string()
    .required("Please select student type")
    .label("Student type"),
  currentLocation: yup
    .string()
    .required("You must input your current location")
    .label("Current location"),
  additionalNotes: yup.string().label("Additional notes"),
});

const SectionThree = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { isLoading, isError, error, isSuccess, mutate } = useGeneralEnquiry();

  const onSubmit = (data) => {
    // console.log(data);

    mutate(data);
  };

  return (
    <div className="jof-contact-us-sect-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="jof-contact-us-sect-3__form"
            >
              <div className="jof-contact-us-sect-3__form-header">
                <h2>Contact Us</h2>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  placeholder="Your name"
                  {...register("fullName")}
                />
                <label htmlFor="floatingInput">Full name</label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.fullName?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  {...register("email")}
                />
                <label htmlFor="floatingInput">Email address</label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.email?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="telephone"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  placeholder="+13243345679"
                  {...register("telephone")}
                />
                <label htmlFor="floatingInput">Telephone number</label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.telephone?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select jof-contact-us-sect-3__form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  {...register("studentType")}
                >
                  <option className="">Select</option>
                  <option className="" value="UK STUDENT">
                    UK STUDENT
                  </option>
                  <option value="EU STUDENT">EU STUDENT</option>
                  <option value="INTERNATIONAL STUDENT">
                    INTERNATIONAL STUDENT
                  </option>
                </select>
                <label htmlFor="floatingSelect">Student type</label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.studentType?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  placeholder="Location"
                  {...register("currentLocation")}
                />
                <label htmlFor="floatingInput">Your current location</label>
                <p className="jof-contact-us-sect-3__form-error">
                  {errors.currentLocation?.message}
                </p>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control jof-contact-us-sect-3__form-control"
                  id="floatingInput"
                  placeholder="Notes"
                  {...register("additionalNotes")}
                />
                <label htmlFor="floatingInput">Additional notes</label>
              </div>
              <p className="j-paragraph-1 jof-contact-us-sect-3__form-para">
                By providing your email address and/or phone number, you are
                confirming that you give us permission to respond to your
                enquiry via these means.
              </p>
              {isSuccess && (
                <p className="j-paragraph-1 fw-bold">
                  Enquiry sent successfully. <br /> Please, do not resend. We
                  will contact you soon. <br />
                  Thank you
                </p>
              )}
              {isError && (
                <p className="j-paragraph-1">{error.response?.data?.message}</p>
              )}
              <div>
                {isLoading ? (
                  <Loading color={"white"} size="60" />
                ) : (
                  <button type="submit" className="btn btn-secondary btn-lg">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;

import React from "react";
import { Link } from "react-router-dom";

const NextStep = () => {
  return (
    <div className="container">
      <div className="jof-course-next-step">
        <p>Take the next step by contacting us</p>
        <Link to="/contact-us">Contact us</Link>
      </div>
    </div>
  );
};

export default NextStep;

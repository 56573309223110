import React from "react";

const ButtonLink = ({ label, icon, onClick: handleClick }) => {
  return (
    <button
      className="j-btn-1 u-margin-top-small"
      onClick={() => handleClick()}
    >
      <span className="j-btn-1__text">{label}</span>{" "}
      <i className={`${icon}`}></i>
    </button>
  );
};

ButtonLink.defaultProps = {
  label: "Get in touch",
  icon: "bi bi-telephone",
};

export default ButtonLink;

import React, { useEffect } from "react";
import Header from "../../components/Header";
import SectionOne from "./components/SectionOne";
import SectionTwo from "./components/SectionTwo";
import SectionThree from "./components/SectionThree";
import Meta from "../../components/Meta";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Meta title={"Contact us | Ex-Nihilo Care Services and Consultancy"} />
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
    </>
  );
};

export default ContactUs;

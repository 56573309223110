import React from "react";

const SectionTwo = () => {
  return (
    <div className="container-fluid jof-student-2-sect-1">
      <div className="container mb-5">
        <h2 className="j-heading-secondary-major jof-student-2-sect-1__heading-main">
          Ex-NiHiLo Care Services and Consultancy
        </h2>
      </div>

      <div className="container jof-student-2-sect-1__container">
        <div className="card mb-3 jof-student-2-sect-1__card">
          <div className="row g-0 jof-student-2-sect-1__row">
            <div className="col-md-4">
              <img
                src="img/nihilo-27.jpg"
                className="img-fluid rounded-start h-100"
                alt="Nihilo"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body jof-student-2-sect-1__card-body">
                <p className="card-text j-paragraph-1  mt-2 mt-md-5">
                  Ex-NiHiLo Care Services and Consultancy was established in
                  2021 with the desire to help people access higher education.
                  Our head office is London, United Kingdom.
                </p>
                <p className="card-text j-paragraph-1">
                  Every student is treated special and provided with the best
                  service. As contributors to ensuring you get the best
                  education, all our services are free.
                </p>
                <p className="card-text j-paragraph-1">
                  From course application through to registration and beyond,
                  our team of student advisors are here to support every student
                  through the admissions process.
                </p>
                <p className="card-text j-paragraph-1">
                  Let us take the stress off your hands and help you get the
                  ideal course, whether you are applying with previous
                  qualifications or with work experience alone. We will help you
                  to submit every application possible.
                </p>
                <p className="card-text j-paragraph-1">
                  Our mission is to clarify and simplify the University
                  application process so that everybody has the chance to
                  complete their chosen degree!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;

import React from "react";

const SectionOne = () => {
  return (
    <div className="jof-about-us-sect-1">
      <div className="jof-about-us-sect-1__text">
        <h2 className="j-heading-secondary-white">About Us</h2>
        <p className="j-paragraph-1">
          We’re passionate about assisting our students in securing admission to
          study their preferred course and supporting them to fullfil their
          potential.
        </p>
      </div>
    </div>
  );
};

export default SectionOne;

import React from "react";

const SectionOne = () => {
  return (
    <div className="jof-courses-sect-1">
      <div className="jof-courses-sect-1__text">
        <h2 className="j-heading-secondary-white">Courses</h2>
        <p className="j-paragraph-1">
          We offer courses ranging from foundation programmes, through to
          postgraduate degrees, covering subject areas including Accounting,
          Business, Computing, Cyber, Digital Marketing, Project Management and
          Web Development
        </p>
      </div>
    </div>
  );
};

export default SectionOne;

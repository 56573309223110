export const countries = [
  {
    id: "uk",
    name: "United Kingdom",
    image: "./img/countries/uk.jpg",
  },
  {
    id: "canada",
    name: "Canada",
    image: "./img/countries/canada.jpg",
  },
  {
    id: "ireland",
    name: "Ireland",
    image: "./img/countries/ireland.jpg",
  },
  {
    id: "usa",
    name: "USA",
    image: "./img/countries/usa.jpg",
  },
  {
    id: "germany",
    name: "Germany",
    image: "./img/countries/germany.jpg",
  },
  {
    id: "france",
    name: "France",
    image: "./img/countries/france.jpg",
  },
  {
    id: "spain",
    name: "Spain",
    image: "./img/countries/spain.jpg",
  },
  {
    id: "dubai",
    name: "Dubai",
    image: "./img/countries/dubai.jpg",
  },
];

import React from "react";

const SectionTwo = () => {
  return (
    <div className="container-fluid jof-contact-us-sect-2">
      <div className="container jof-contact-us-sect-2__container">
        <div className="jof-contact-us-sect-2__header">
          <h3>Be Part Of Ex-NiHilo Services</h3>
        </div>
        <div className="row jof-contact-us-sect-2__row">
          <div className="col-12 col-md-6">
            <p className="j-paragraph-1">
              We are passionate about supporting your desire to go to school to
              reach your full potential. Please get in touch with us we are open
              from 9:00am to 5:00pm Monday to Friday. We are also available on
              Whatsapp 24/7.
            </p>
            <div className="jof-contact-us-sect-2__border">
              <p className="j-paragraph-1 ">
                Please don’t hesitate to contact us to commence your journey to
                higher education.
              </p>
            </div>
            <p className="j-paragraph-1 mt-3">
              EX-NIHILO CARE SERVICES AND CONSULTANCY LIMITED
            </p>
            <p className="j-paragraph-1">127 Nightingale Vale</p>
            <p className="j-paragraph-1">London</p>
            <div className="jof-contact-us-sect-2__border">
              <p className="j-paragraph-1">SE18 4EL</p>
            </div>
            <div className="j-paragraph-1 mt-3">
              <span>Whatsapp: </span>
              <a href="https://wa.link/4ika3a" target="_blank" rel="noreferrer">
                07511187493
              </a>
            </div>
            <p className="j-paragraph-1 mt-5">
              Alternatively, you can complete the form below and we will contact
              you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;

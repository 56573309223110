import React from "react";

const SectionTwo = () => {
  return (
    <div className="container-fluid jof-student-2-sect-2">
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 col-md-4">
            <h3 className="jof-student-2-sect-2__header-small">Ex-Nihilo</h3>
            <img
              className="img-fluid"
              src="img/nihilo-23.png"
              alt="nihilo-23"
            />
          </div>
          <div className="col-12 col-md-8">
            <h2 className="j-heading-secondary-major jof-student-2-sect-2__heading-main">
              Our services also include
            </h2>
            <ul className="list-group list-group-flush jof-student-2-sect-2__list-group">
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                Processing your admission to university/college/boarding school
              </li>
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                Visa assistance
              </li>
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                Partial scholarship assistance
              </li>
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                Tailored assistance
              </li>
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                Immigration assistance
              </li>
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                100% free ongoing student support
              </li>
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                Registration for entrance and English language tests
              </li>
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                University selection support
              </li>
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                Student accommodation
              </li>
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                Pre-departure and post-arrival services
              </li>
              <li className="list-group-item jof-student-2-sect-2__list-group-item mb-3">
                University student airport pick-up
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;

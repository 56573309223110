import React from "react";
import Header from "../../components/Header";
import Meta from "../../components/Meta";
import SectionOne from "./components/SectionOne";
import SectionThree from "./components/SectionThree";
import SectionTwo from "./components/SectionTwo";
import OtherCourses from "./components/OtherCourses";
import NextStep from "./components/NextStep";
import { useEffect } from "react";

const Courses = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Meta title={"Courses | Ex-Nihilo Care Services and Consultancy"} />
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <OtherCourses />
      <NextStep />
    </>
  );
};

export default Courses;

import React from "react";
import { generateNumbers } from "../utils/pageNumber";

const Pagination = ({
  page,
  totalPage,
  hasNextPage,
  previous,
  next,
  goToPage,
}) => {
  const pageNumbers = totalPage ? totalPage : 0;

  return (
    <nav aria-label="Page navigation example jof-pagination-nav">
      <ul className="pagination jof-pagination">
        <li className="page-item">
          <button
            onClick={() => previous()}
            disabled={page === 1}
            className="page-link jof-pagination__page-link"
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {generateNumbers(pageNumbers).map((page) => (
          <li key={page} className="page-item">
            <button
              onClick={() => goToPage(page)}
              className="page-link jof-pagination__page-link"
            >
              {page}
            </button>
          </li>
        ))}

        <li className="page-item">
          <button
            onClick={() => next()}
            disabled={!hasNextPage}
            className="page-link jof-pagination__page-link"
            aria-label="Next"
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
      <span className="jof-pagination__page-number">
        Page {page} of {totalPage}
      </span>
    </nav>
  );
};

export default Pagination;

import React from "react";

const Ireland = () => {
  return (
    <div className="jof-other-courses__unis">
      <h4 className="mb-5">Ireland</h4>
      <div className="jof-other-courses__unis-universities">
        <h5>Ireland Universities</h5>
        <div className="jof-other-courses__unis-universities-main">
          <div className="jof-other-courses__unis-universities-main-item">
            <i className="bi bi-check-circle-fill"></i>
            <div>
              <span>HOLMES INSTITUTE IRELAND. (Undergraduate only)</span> <br />
              <span>Holmes Institute, Dublin Ireland</span> <br />
              <span>BA HONS Business</span> <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ireland;

import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import FilterBy from "./FilterBy";
import SearchCourse from "./SearchCourse";

const SectionThree = () => {
  return (
    <div className="jof-courses-sect-3">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 mb-3 mb-md-0 ps-5 ps-md-0">
            <SearchCourse />
            <FilterBy />
          </div>
          <div className="col-12 col-md-8">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;

import { useQuery } from "@tanstack/react-query";
import { searchCourse } from "../courseService";

export function useCourseSearch({ keyword, page }) {
  return useQuery(
    ["course-search", page],
    () => searchCourse({ keyword, page }),
    {
      enabled: false,
    }
  );
}

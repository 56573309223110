import React from "react";
import Header from "./Header";

const NotFound = () => {
  return (
    <div className="jof-error-page">
      <Header />
      <div className="jof-error-page__content">
        <h1 className="fw-bolder">Problem Loading Page</h1>
        <h2 className="text-center fw-bold">Error 404</h2>
        <p className="text-center" style={{ fontSize: "1.3em" }}>
          Page not found
        </p>
        <p className="text-center" style={{ fontSize: "1.1em" }}>
          Check the URL then try again
        </p>
      </div>
    </div>
  );
};

export default NotFound;

import React from "react";
import Header from "../../components/Header";
import Meta from "../../components/Meta";
import CourseUploadForm from "./components/CourseUploadForm";

const Admin = () => {
  return (
    <div className="jof-admin">
      <Meta title={"Admin | Ex-Nihilo Care Services and Consultancy"} />
      <Header />
      <CourseUploadForm />
    </div>
  );
};

export default Admin;
